//@ts-nocheck
//TODO remove nocheck above
import type { Edge, Node } from 'reactflow';

import { BotModuleData } from '@/domain/bot';

/**
 * Transforms data format used to store modules at fronted (nodes and edges) to modules used in database (mappie)
 * @param nodes - array containg data about nodes used on frontend - translated to module params
 * @param edges - array containg data about edges used on frontend - translated to module handlers
 * @returns modules - object representing modules in database (mappie)
 */

const buildHandlers = (node, edges) => {
    let handlersObject;

    switch (node?.data?.type) {
        case 'Exit':
            handlersObject = undefined;
            break;
        case 'YesNoConfirmation':
            handlersObject = {
                node_exit: getModuleHandlers(edges, node.id, 'Y'),
                on_no: getModuleHandlers(edges, node.id, 'N'),
                on_fallback: getModuleHandlers(edges, node.id, 'F'),
                on_escape: getModuleHandlers(edges, node.id, 'S'),
            };
            break;
        case 'GPTModule':
            handlersObject = {
                node_exit: getModuleHandlers(edges, node.id, 'E'),
                on_timeout_exception: getModuleHandlers(edges, node.id, 'M'),
                on_content_filter_exception: getModuleHandlers(
                    edges,
                    node.id,
                    'L',
                ),
                on_exception: getModuleHandlers(edges, node.id, 'C'),
            };
            break;
        case 'MSearchModule':
            handlersObject = {
                node_exit: getModuleHandlers(edges, node.id, 'E'),
                on_error: getModuleHandlers(edges, node.id, 'C'),
                on_no_result_found: getModuleHandlers(edges, node.id, 'L'),
            };
        case 'NumericRatingModule':
            handlersObject = {
                node_exit: getModuleHandlers(edges, node.id, 'E'),
                on_fallback: getModuleHandlers(edges, node.id, 'F'),
            };
            break;
        case 'ValueRangeRouter':
            const numOfIntervals =
                node?.data?.params?.interval_partition_points?.length + 1;
            handlersObject = {};

            for (let i = 0; i < numOfIntervals; i++) {
                handlersObject[`on_value_in_range_${i}`] = getModuleHandlers(
                    edges,
                    node.id,
                    `${i}`,
                );
            }
            handlersObject['on_value_not_set'] = getModuleHandlers(
                edges,
                node.id,
                'N',
            );
            handlersObject['on_value_out_of_bounds'] = getModuleHandlers(
                edges,
                node.id,
                'O',
            );
            break;
        default:
            handlersObject = {
                node_exit: getModuleHandlers(edges, node.id, 'E'),
            };
    }

    return handlersObject;
};

export const getModuleTypeHandlers = (
    nodeType: BotModuleType,
    selectedNode?: Node = 0,
): string[] => {
    switch (nodeType) {
        case 'Exit':
            return [];
        case 'YesNoConfirmation':
            return ['Y', 'N', 'F', 'S'];
        case 'NumericRatingModue':
            return ['E', 'F'];
        case 'ValueRangeRouter':
            const numOfIntervals = selectedNode?.data
                ? selectedNode?.data?.params?.interval_partition_points + 1
                : selectedNode + 1;
            const indedexedPorts = Array.from(
                {
                    length: numOfIntervals,
                },
                (_, index) => String(index),
            );
            indedexedPorts.push('N');
            indedexedPorts.push('O');
            return indedexedPorts;
        case 'GPTModule':
            return ['E', 'M', 'L', 'C'];
        case 'MSearchModule':
            return ['E', 'C', 'L'];
        default:
            return ['E'];
    }
};

export const flowDataToModules = (
    nodes: Node<BotModuleData>[],
    edges: Edge[],
) => {
    const modules: { [key: string]: any } = {};

    for (const node of nodes) {
        if (node.id !== 'start') {
            switch (node.id) {
            }
            modules[node.id] = {
                type:
                    node?.data?.type === 'CustomInternalModule'
                        ? node.data.params.moduleType
                        : node?.data?.type,
                params:
                    node?.data?.type === 'CustomInternalModule' ||
                    node?.data?.type === 'Exit'
                        ? undefined
                        : node?.data?.type === 'YesNoConfirmation' ||
                          node?.data?.type === 'ValueRangeRouter'
                        ? {
                              ...node?.data?.params,
                              escape_mode:
                                  node?.data?.params?.escape_mode ===
                                  'undefined'
                                      ? undefined
                                      : node?.data?.params?.escape_mode,
                          }
                        : node?.data?.params,
                source: node?.data?.source,
                // problem with type - any of keys in handlers can be undefined here
                handlers: buildHandlers(node, edges),
            };
        }
    }

    return modules;
};

const getModuleHandlers = (
    edges: Edge[],
    startString: string,
    endString: string,
) => {
    const edge = edges.find(
        (e) => e.source === startString && e.id.endsWith(endString),
    );

    return edge?.target;
};

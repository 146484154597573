import _ from 'lodash';
import { addEdge, Edge, Node } from 'reactflow';

import { createSimpleEdgeReactFlow } from './create-simple-edge';
import { getModuleTypeHandlers } from './graph-data-to-modules';

import { BotModuleData, BotModuleType } from '@/domain/bot';

// const findNextLinearNode = (
//     nodes: Node<BotModuleData>[],
//     edges: Edge[],
//     startNode: Node<BotModuleData>,
// ): Node<BotModuleData> | null => {
//     const candidates = edges.filter((x) => x.source == startNode.id);
//     if (candidates.length != 1) {
//         return null;
//     }
//     const res = nodes.find((x) => x.id == candidates[0].target);
//     return res ?? null;
// };

export const autoInsertIntoLinearGraph = (
    nodes: Node<BotModuleData>[],
    edges: Edge[],
    newNodeId: string,
    newNodeType: BotModuleType,
    selectedHandle: string | undefined,
): Edge[] | undefined => {
    const replaceEnd = edges.find((x) => x.target == 'end');
    const replaceEndSource = nodes.find((x) => x.id == replaceEnd?.source);
    const selectedNode = nodes.find((x) => x.selected);
    const portFromHandle = selectedHandle?.slice(-1);
    const nodeIdFromHandle = selectedHandle?.slice(0, -2);

    if (nodeIdFromHandle && portFromHandle) {
        edges = addEdge(
            createSimpleEdgeReactFlow(
                nodeIdFromHandle,
                newNodeId,
                portFromHandle,
            ),
            edges,
        );
        return edges;
    } else if (selectedNode && selectedNode.type !== 'ExitNode') {
        const nextEdges: { edge: Edge; port: string }[] = [];

        for (const port of getModuleTypeHandlers(
            selectedNode?.data?.type,
            selectedNode,
        )) {
            const nextEdge = edges.find(
                (x) =>
                    x.source == selectedNode?.id &&
                    x.sourceHandle?.slice(-1) == port,
            );
            if (nextEdge) {
                nextEdges.push({ edge: nextEdge, port });
            }
        }

        const uniqTargetCount = _.uniq(
            nextEdges.map((x) => x.edge.target),
        ).length;
        if (uniqTargetCount >= 1) {
            const origTarget = nextEdges[0].edge.target;
            for (const nextEdge of uniqTargetCount == 1
                ? nextEdges
                : [nextEdges[0]]) {
                edges = edges.filter((x) => x != nextEdge.edge);
                edges = addEdge(
                    createSimpleEdgeReactFlow(
                        nextEdge.edge.source,
                        newNodeId,
                        nextEdge.port,
                    ),
                    edges,
                );
            }

            for (const port of getModuleTypeHandlers(newNodeType)) {
                edges = addEdge(
                    createSimpleEdgeReactFlow(newNodeId, origTarget, port),
                    edges,
                );
            }

            return edges;
        } else {
            const selNodeFirstPort = getModuleTypeHandlers(
                selectedNode?.data?.type,
                selectedNode,
            )[0];
            edges = addEdge(
                createSimpleEdgeReactFlow(
                    selectedNode.id,
                    newNodeId,
                    selNodeFirstPort,
                ),
                edges,
            );
            return edges;
        }
    }

    if (replaceEnd) {
        edges = edges.filter(
            (x) => !(x.source == replaceEnd.source && x.target == 'end'),
        );

        for (const port of getModuleTypeHandlers(
            replaceEndSource?.data?.type,
        )) {
            if (
                edges.find(
                    (x) =>
                        x.source == replaceEnd?.source &&
                        x.sourceHandle?.slice(-1) == port,
                )
            ) {
                continue;
            }
            edges = addEdge(
                createSimpleEdgeReactFlow(replaceEnd?.source, newNodeId, port),
                edges,
            );
        }

        for (const port of getModuleTypeHandlers(newNodeType)) {
            edges = addEdge(
                createSimpleEdgeReactFlow(newNodeId, 'end', port),
                edges,
            );
        }

        return edges;
    } else {
        for (const port of getModuleTypeHandlers(newNodeType)) {
            edges = addEdge(
                createSimpleEdgeReactFlow(newNodeId, 'end', port),
                edges,
            );
        }

        return edges;
    }
};

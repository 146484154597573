import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { shallow } from 'zustand/shallow';

import { BaseNodeForm } from '../base-node-form';

import { AlternativeYesNoPhrasesBlock } from './alternative-yes-no-phrases-block';
import { FallbackBlock } from './fallback-block';
import { QuestionAndAnswersBlock } from './question-and-answer-block';

import { YesNoConfirmationBotModuleData } from '@/domain/bot';
import { NodeFormProps } from '@/domain/node-form-props';
import { useTelmaPortalStore } from '@/store/telma-portal-store';

const alternativesSchema = yup.lazy((val) =>
    Array.isArray(val)
        ? yup.array().of(yup.string().max(500, 'too_long'))
        : yup.string().max(500, 'too_long'),
);

export const botYesNoConfirmationNodeSchema = yup.object({
    question: yup.string().max(500, 'too_long'),
    prompt_yes_acknowledgment: yup.string().max(500, 'too_long'),
    prompt_no_acknowledgment: yup.string().max(500, 'too_long'),
    yes_alternatives: alternativesSchema,
    no_alternatives: alternativesSchema,
    escape_alternatives: alternativesSchema,
    prompt_fallback: yup.string().max(500, 'too_long'),
});

export const YesNoConfirmationNodeForm: FC<NodeFormProps> = ({ nodeIndex }) => {
    const [setNode, node] = useTelmaPortalStore(
        (state) => [state.setNode, state.editingBot.nodesReactFlow[nodeIndex]],
        shallow,
    );

    const { handleSubmit, control, setValue } = useForm<
        YesNoConfirmationBotModuleData['params']
    >({
        resolver: yupResolver(botYesNoConfirmationNodeSchema),
        defaultValues: {
            //@ts-ignore - data type problem
            ...node?.data?.params,
            //@ts-ignore - data type problem
            number_of_retries: node?.data?.params?.number_of_retries ?? 2,
            yes_alternatives:
                //@ts-ignore - data type problem
                typeof node.data.params.yes_alternatives === 'string'
                    ? //@ts-ignore - data type problem
                      node.data.params.yes_alternatives
                    : //@ts-ignore - data type problem
                      node.data.params.yes_alternatives.join(', '),
            no_alternatives:
                //@ts-ignore - data type problem
                typeof node.data.params.no_alternatives === 'string'
                    ? //@ts-ignore - data type problem
                      node.data.params.no_alternatives
                    : //@ts-ignore - data type problem
                      node.data.params.no_alternatives.join(', '),
            //@ts-ignore - data type problem
            escape_alternatives: !!node?.data?.params?.escape_alternatives
                ? //@ts-ignore - data type problem
                  typeof node.data.params.escape_alternatives === 'string'
                    ? //@ts-ignore - data type problem
                      node.data.params.escape_alternatives
                    : //@ts-ignore - data type problem
                      node.data.params.escape_alternatives.join(', ')
                : '',
        },
    });

    const botNodeSubmit: SubmitHandler<
        YesNoConfirmationBotModuleData['params']
    > = (formData) => {
        setNode(nodeIndex, formData);
    };

    return (
        <BaseNodeForm nodeId={node.id} nodeTitle={node.type}>
            <form onBlur={handleSubmit(botNodeSubmit)}>
                <QuestionAndAnswersBlock
                    nodeIndex={nodeIndex}
                    control={control}
                    setValue={setValue}
                />
                <AlternativeYesNoPhrasesBlock
                    nodeIndex={nodeIndex}
                    control={control}
                />
                <FallbackBlock nodeIndex={nodeIndex} control={control} />
            </form>
        </BaseNodeForm>
    );
};

import {
    type Edge,
    getConnectedEdges,
    getIncomers,
    getOutgoers,
    type Node,
} from 'reactflow';

import { createSimpleEdgeReactFlow } from './create-simple-edge';

import { BotModuleData } from '@/domain/bot';

export const createNewEdgesOnNodeDelete = (
    currentEdges: Edge[],
    node: Node<BotModuleData>,
    currentNodes: Node<BotModuleData>[],
) => {
    const outgoers = getOutgoers(node, currentNodes, currentEdges);

    const connectedEdges = getConnectedEdges([node], currentEdges);
    const remainingEdges = currentEdges.filter(
        (edge) => !connectedEdges.includes(edge),
    );

    if (outgoers.length !== 1) {
        return [...remainingEdges];
    }

    const incomers = getIncomers(node, currentNodes, currentEdges);
    const targetNodeId = outgoers[0].id;

    const createdEdges = incomers.flatMap(({ id: sourceNodeId }) => {
        const incomingEdges = connectedEdges.filter(
            (edge) => edge.source === sourceNodeId,
        );

        return incomingEdges.map(({ source, sourceHandle }) =>
            createSimpleEdgeReactFlow(source, targetNodeId, sourceHandle!),
        );
    });

    return [...remainingEdges, ...createdEdges];
};

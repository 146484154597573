import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { shallow } from 'zustand/shallow';

import { BotContentTextField } from '../custom-form-text-field';

import { BaseNodeForm } from './base-node-form';

import { PromptBotModuleData } from '@/domain/bot';
import { NodeFormProps } from '@/domain/node-form-props';
import { useTelmaPortalStore } from '@/store/telma-portal-store';
import { useSaveBotVersionEnabled } from '@/utils/use-save-bot-version-enabled';

export const promptNodeSchema = yup.object({
    prompt: yup.string().max(500, 'too_long'),
});

export const PromptNodeForm: FC<NodeFormProps> = ({ nodeIndex }) => {
    const [setNode, node] = useTelmaPortalStore(
        (state) => [state.setNode, state.editingBot.nodesReactFlow[nodeIndex]],
        shallow,
    );
    const saveEnabled = useSaveBotVersionEnabled();

    const { handleSubmit, control } = useForm<PromptBotModuleData['params']>({
        //@ts-ignore - data type problem
        resolver: yupResolver(promptNodeSchema),
        //@ts-ignore - data type problem
        defaultValues: node?.data?.params,
    });

    const botNodeSubmit: SubmitHandler<PromptBotModuleData['params']> = (
        formData,
    ) => {
        setNode(nodeIndex, formData);
    };

    return (
        <BaseNodeForm nodeId={node.id} nodeTitle={node.type}>
            <form onChange={handleSubmit(botNodeSubmit)}>
                <Controller
                    name='prompt'
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <BotContentTextField
                            {...field}
                            fieldError={error}
                            autoResize
                            autoFocus
                            disabled={!saveEnabled}
                        />
                    )}
                />
            </form>
        </BaseNodeForm>
    );
};

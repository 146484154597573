/**
 * Color intention that you want to use in your theme
 * @param {JsonObject} theme Theme customization object
 */
declare module '@mui/material/styles/createPalette' {
    interface Palette {
        summary: PaletteColor;
    }
    interface PaletteOptions {
        summary: PaletteColorOptions;
    }
    interface ExtendedPaletteColorOptions extends SimplePaletteColorOptions {
        ultraLight: string;
        pending: string;
        initiated: string;
        denied: string;
        toRetry: string;
        unconnected: string;
        finished: string;
        bgPinkDark: string;
        bgPinkHover: string;
    }
    interface ExtendedPaletteOptions extends PaletteOptions {
        primary: ExtendedPaletteColorOptions;
        secondary: ExtendedPaletteColorOptions;
        summary: ExtendedPaletteColorOptions;
        text: Partial<TypeText>;
        error: ExtendedPaletteColorOptions;
        warning: ExtendedPaletteColorOptions;
        info: ExtendedPaletteColorOptions;
        success: ExtendedPaletteColorOptions;
    }
    interface PaletteColor {
        ultraLight: string;
        veryLight: string;
        lighter: string;
        pending: string;
        initiated: string;
        denied: string;
        toRetry: string;
        unconnected: string;
        finished: string;
        bgPinkDark: string;
        bgPinkHover: string;
    }
}

export default function themePalette(theme: any) {
    return {
        mode: theme?.customization?.navType,
        common: {
            black: theme.colors?.darkPaper,
        },
        primary: {
            ultraLight: '#fcf5f7',
            light: theme.colors?.primaryLight,
            main: theme.colors?.primaryMain,
            dark: theme.colors?.primaryDark,
            veryLight: theme.colors?.primary200,
            lighter: theme.colors?.primary800,
            pending: theme.colors?.summaryPending,
        },
        secondary: {
            light: theme.colors?.secondaryLight,
            main: theme.colors?.secondaryMain,
            dark: theme.colors?.secondaryDark,
            veryLight: theme.colors?.secondary200,
            lighter: theme.colors?.secondary800,
            bgPinkDark: theme.colors?.secondaryBgPinkDark,
            bgPinkHover: theme.colors?.secondaryBgPinkHover,
        },
        summary: {
            pending: theme.colors?.summaryPending,
            initiated: theme.colors?.summaryInitiated,
            denied: theme.colors?.summaryDenied,
            toRetry: theme.colors?.summaryToRetry,
            unconnected: theme.colors?.summaryUnconnected,
            finished: theme.colors?.summaryFinished,
        },
        error: {
            light: theme.colors?.errorLight,
            main: theme.colors?.errorMain,
            dark: theme.colors?.errorDark,
        },
        orange: {
            light: theme.colors?.orangeLight,
            main: theme.colors?.orangeMain,
            dark: theme.colors?.orangeDark,
        },
        warning: {
            light: theme.colors?.warningLight,
            main: theme.colors?.warningMain,
            dark: theme.colors?.warningDark,
        },
        success: {
            light: theme.colors?.successLight,
            200: theme.colors?.success200,
            main: theme.colors?.successMain,
            dark: theme.colors?.successDark,
        },
        grey: {
            50: theme.colors?.grey50,
            100: theme.colors?.grey100,
            500: theme.darkTextSecondary,
            600: theme.heading,
            700: theme.darkTextPrimary,
            900: theme.textDark,
        },
        dark: {
            light: theme.colors?.darkTextPrimary,
            main: theme.colors?.darkLevel1,
            dark: theme.colors?.darkLevel2,
            800: theme.colors?.darkBackground,
            900: theme.colors?.darkPaper,
        },
        text: {
            primary: theme.darkTextPrimary,
            secondary: theme.darkTextSecondary,
            dark: theme.textDark,
            hint: theme.colors?.grey100,
        },
        background: {
            paper: theme.paper,
            default: theme.backgroundDefault,
        },
    };
}

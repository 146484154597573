import axios from 'axios';

import { Bot, TaskValue } from '@/domain/bot';
import { CallDetail } from '@/domain/call-details';
import {
    CampaignOmnichannel,
    EditedCampaignFrontend,
    NewCampaign,
    NewCampaignFrontend,
} from '@/domain/campaign';
import { CampaignStats } from '@/domain/campaign-stats';
import {
    ConversationTask,
    ConversationTaskStatus,
} from '@/domain/conversation-task';

const CAMPAIGNS_API = '/api/campaigns';

export const getCampaigns = async () => {
    const { data: response } = await axios.get<CampaignOmnichannel[]>(
        `${CAMPAIGNS_API}`,
    );
    return response;
};

export const getCampaign = async (campaignId: string | string[]) => {
    const { data: response } = await axios.get<CampaignOmnichannel>(
        `${CAMPAIGNS_API}/${campaignId}`,
    );
    return response;
};

export const deleteCampaign = async (campaignId: string) => {
    const { data: response } = await axios.delete(
        `${CAMPAIGNS_API}/${campaignId}`,
    );
    return response;
};

export const postCampaign = async (data: NewCampaignFrontend) => {
    const { data: response } = await axios.post<{ campaignId: string }>(
        CAMPAIGNS_API,
        data,
    );
    return response;
};

export const patchCampaign = async (
    campaignId: string,
    campaignUpdate: EditedCampaignFrontend | { is_paused: boolean },
) => {
    const { data: response } = await axios.patch(
        `${CAMPAIGNS_API}/${String(campaignId)}`,
        campaignUpdate,
    );
    return response;
};

// upload script file to api
export const postScript = async (
    campaignId: string,
    botId: string,
    botVersionId: string,
    script: string,
    ignoreMissingColumns: boolean,
) => {
    const url = `${CAMPAIGNS_API}/${String(campaignId)}/script`;
    const { data: response } = await axios.post(url, {
        script,
        botId,
        botVersionId,
        ignoreMissingColumns,
    });
    return response;
};

export const handleNumbers = async (
    campaignId: string,
    botId: string,
    botVersionId: string,
    ignoreMissingColumns: boolean,
    buildingId: string,
    action: 'fetch' | 'send',
    numbers?: string[],
) => {
    const url = `${CAMPAIGNS_API}/${String(campaignId)}/numbers`;

    if (action === 'fetch') {
        const { data: response } = await axios.get(url, {
            headers: {
                'X-Bot-ID': botId,
                'X-Bot-Version-ID': botVersionId,
                'X-Ignore-Missing-Columns': ignoreMissingColumns,
                'X-Building-ID': buildingId,
            },
        });
        return response;
    } else if (action === 'send') {
        const requestBody = {
            botId,
            botVersionId,
            ignoreMissingColumns,
            buildingId,
            action,
            numbers,
        };

        const { data: response } = await axios.post(url, requestBody);
        return response;
    } else {
        throw new Error(`Unsupported action: ${action}`);
    }
};

export const postConversationTasks = async (
    campaignId: string,
    conversationTask: {
        currentBot: Bot;
        callee: { phone: string; phonePrefix: string };
    },
    accountId?: string | null,
) => {
    const url = `${CAMPAIGNS_API}/${String(campaignId)}/conversation_tasks`;

    const { data: response } = await axios.post(url, conversationTask, {
        params: {
            account_id: accountId,
        },
    });
    return response;
};

export const postVoicebotCall = async (
    campaignId: string,
    botId: string,
    callee: { phone: string; phonePrefix: string },
    taskValues: TaskValue[],
) => {
    const url = `${CAMPAIGNS_API}/${String(campaignId)}/${String(
        botId,
    )}/voicebot_call`;

    const { data: response } = await axios.post(url, { callee, taskValues });
    return response;
};

export const getFinalReport = async (
    campaignId: string,
    omnichannelAgentVersion?: string,
) => {
    const { data: response } = await axios.get(
        `${CAMPAIGNS_API}/${String(campaignId)}/final_report`,
        {
            params: { version: omnichannelAgentVersion },
        },
    );
    return response;
};

export const getCampaignStats = async (campaignId: string) => {
    const { data: response } = await axios.get<CampaignStats>(
        `${CAMPAIGNS_API}/${String(campaignId)}/stats`,
    );
    return response;
};

export const getCallDetails = async (
    campaignId: string,
    omnichannelAgentVersion?: string,
) => {
    const url = `${CAMPAIGNS_API}/${String(campaignId)}/call_details`;
    const { data: response } = await axios.get<CallDetail[]>(url, {
        params: { version: omnichannelAgentVersion },
    });
    return response;
};

// export const getConversationTasks = async (
//     campaignId: string,
//     omnichannelAgentVersion?: string,
// ) => {
//     const url = `${CAMPAIGNS_API}/${String(campaignId)}/conversation_tasks`;
//     const { data: response } = await axios.get<ConversationTask[]>(url, {
//         params: { version: omnichannelAgentVersion },
//     });
//     return response;
// };

export const getSingleConversationTaskStatus = async (
    campaignId: string,
    taskId: number,
    omnichannelAgentVersion?: string,
) => {
    const url = `${CAMPAIGNS_API}/${String(
        campaignId,
    )}/conversation_tasks/${String(taskId)}/status`;

    const { data: response } = await axios.get<ConversationTaskStatus>(url, {
        params: { version: omnichannelAgentVersion },
    });
    return response;
};

import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { shallow } from 'zustand/shallow';

import { useTelmaPortalStore } from '@/store/telma-portal-store';

export const useSaveBotVersionEnabled = () => {
    const { data: session } = useSession();
    const isBotEditor = !!session?.user?.roles?.isBotEditor;

    const router = useRouter();

    const botVersionId = router.query.version_id as string;

    const [currentBotVersion] = useTelmaPortalStore(
        (state) => [state.currentBotVersion],
        shallow,
    );

    const saveEnabled = isBotEditor && botVersionId === currentBotVersion;

    return saveEnabled;
};

import {
    Box,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
} from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Language {
    nameEng: string;
    name: string;
    value: string;
}

const languageList = [
    {
        nameEng: 'UK',
        name: 'English',
        value: 'en',
    },
    {
        nameEng: 'Czech',
        name: 'Čeština',
        value: 'cs',
    },
];

// ==============================|| LANGUAGE LIST ITEM ||============================== //

export const LanguageList: FC = () => {
    const { i18n } = useTranslation();

    return (
        <Box sx={{ width: '100%', minWidth: 200, bgcolor: 'background.paper' }}>
            <nav>
                <List>
                    {languageList.map((lang: Language) => (
                        <ListItem key={lang.name} disablePadding>
                            <ListItemButton
                                selected={lang.value === i18n.language}
                                onClick={() => i18n.changeLanguage(lang.value)}
                                style={{ textAlign: 'right' }}
                            >
                                <ListItemText>
                                    <span
                                        style={{
                                            color: '#697586',
                                            fontSize: '0.75rem',
                                        }}
                                    >{`(${lang.nameEng})`}</span>{' '}
                                    {`${lang.name}`}
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </nav>
        </Box>
    );
};

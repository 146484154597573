import {
    Box,
    TextField,
    TextFieldProps,
    TextFieldVariants,
} from '@mui/material';
import { useSession } from 'next-auth/react';
import { forwardRef, Ref } from 'react';
import {
    ControllerRenderProps,
    FieldError,
    FieldValues,
    Path,
} from 'react-hook-form';

import { StyledTextareaAutoresize } from './styled-textarea-autoresize';

import { useTelmaPortalStore } from '@/store/telma-portal-store';
import { displayErrorMessage } from '@/utils/display-error-message';

//TODO  Function components cannot be given refs. Attempts to access this ref will fail. Did you mean to use React.forwardRef()?

type CustomFormTextFieldProps<
    T extends FieldValues,
    Variant extends TextFieldVariants,
> = Omit<TextFieldProps, 'variant'> &
    ControllerRenderProps<T, Path<T>> & {
        fieldError?: FieldError;
        variant?: Variant;
    } & {
        autoResize?: boolean;
    };

export const BotContentTextField = forwardRef(
    <T extends FieldValues, Variant extends TextFieldVariants>(
        props: CustomFormTextFieldProps<T, Variant>,
        ref: Ref<any>,
    ) => {
        const { data: session } = useSession();
        const isBotEditor = !!session?.user?.roles?.isBotEditor;

        const language = useTelmaPortalStore(
            (state) => state.editingBot.language,
        );

        return (
            <FormTextField
                {...props}
                ref={ref}
                dir={language === 'ar' ? 'rtl' : 'ltr'}
                disabled={!isBotEditor || props.disabled}
            />
        );
    },
);

BotContentTextField.displayName = 'BotContentTextField';

export const FormTextField = forwardRef(
    <T extends FieldValues, Variant extends TextFieldVariants>(
        {
            fieldError,
            autoResize,
            ...rest
        }: CustomFormTextFieldProps<T, Variant>,
        ref: Ref<any>,
    ) => {
        const helperText = displayErrorMessage(
            fieldError?.message,
            !!fieldError,
        );

        if (autoResize) {
            return (
                <>
                    <StyledTextareaAutoresize
                        maxRows={5}
                        {...rest}
                        ref={ref}
                        error={fieldError}
                    />

                    {helperText && (
                        <Box
                            sx={{
                                color: 'rgb(244, 67, 54)',
                                margin: '3px 14px 0px;',
                                fontSize: '0.75rem',
                            }}
                        >
                            {helperText}
                        </Box>
                    )}
                </>
            );
        }
        return (
            <TextField
                {...rest}
                ref={ref}
                error={!!fieldError}
                helperText={helperText}
            />
        );
    },
);

FormTextField.displayName = 'FormTextField';

export const FormTextFieldWithRef = forwardRef(
    <T extends FieldValues, Variant extends TextFieldVariants>(
        { fieldError, ...rest }: CustomFormTextFieldProps<T, Variant>,
        ref: Ref<any>,
    ) => {
        const helperText = displayErrorMessage(
            fieldError?.message,
            !!fieldError,
        );

        return (
            <TextField
                {...rest}
                ref={ref}
                error={!!fieldError}
                helperText={helperText}
            />
        );
    },
);

FormTextFieldWithRef.displayName = 'FormTextFieldWithRef';

//TODO  Warning: Function components cannot be given refs. Attempts to access this ref will fail. Did you mean to use React.forwardRef()?
//Check the render method of `Controller`.

import { yupResolver } from '@hookform/resolvers/yup';
import {
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import React, { FC, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { addEdge, Connection, Edge } from 'reactflow';
import * as yup from 'yup';
import { shallow } from 'zustand/shallow';

import { NodeOutputDetail } from '../node-output-detail';
import { NodeOutputsDetails } from '../node-outputs-details';

import { IntervalFormData } from '@/data/value-range-router';
import { NodeFormProps } from '@/domain/node-form-props';
import { useTelmaPortalStore } from '@/store/telma-portal-store';
import { isInRange } from '@/utils/isInRange';

export const botValueRangeRouterNodeSchema = yup.object().shape({
    value: yup.string().required('Module id is required'),
    min_value: yup
        .number()
        .required('Min value is required')
        .test(
            'is-greater-than-first-partition',
            'Min value should be less than or equal to the first partition point',
            function (min_value) {
                let { interval_partition_points } = this.parent;

                if (
                    !interval_partition_points ||
                    interval_partition_points.length === 0
                ) {
                    return true;
                }

                interval_partition_points = [...interval_partition_points].sort(
                    (a, b) => a - b,
                );

                return min_value <= interval_partition_points[0];
            },
        ),
    max_value: yup
        .number()
        .required('Max value is required')
        .test(
            'is-less-than-last-partition',
            'Max value should be greater than the last partition point',
            function (max_value) {
                let { interval_partition_points } = this.parent;

                if (
                    !interval_partition_points ||
                    interval_partition_points.length === 0
                ) {
                    return true;
                }

                interval_partition_points = [...interval_partition_points].sort(
                    (a, b) => a - b,
                );

                return (
                    max_value >=
                    interval_partition_points[
                        interval_partition_points.length - 1
                    ] +
                        1
                );
            },
        ),
    interval_partition_points: yup
        .array()
        .of(yup.number().required('Partition point is required')),
});

export const ValueRangeRouterNodeForm: FC<NodeFormProps> = ({ nodeIndex }) => {
    const [breakPoint, setBreakPoint] = useState<string>('');

    const [setNode, node, edges, setEdges] = useTelmaPortalStore(
        (state) => [
            state.setNode,
            state.editingBot.nodesReactFlow[nodeIndex],
            state.editingBot.edgesReactFlow,
            state.setEdges,
        ],
        shallow,
    );

    const {
        control,
        formState: { errors },
        setValue,
        watch,
    } = useForm<IntervalFormData>({
        resolver: yupResolver(botValueRangeRouterNodeSchema),
        mode: 'onChange',
        // @ts-ignore - part of the data type problem
        defaultValues: node?.data?.params || {
            value: '',
            min_value: undefined,
            max_value: undefined,
            interval_partition_points: [],
        },
    });

    const minValue = watch('min_value');
    const maxValue = watch('max_value');
    const partitionPoints = watch('interval_partition_points') || [];
    const sortedPartitionPoints = [...partitionPoints].sort((a, b) => a - b);
    const inputRef = useRef<HTMLInputElement>(null);

    const { t } = useTranslation();

    function computeIntervals() {
        if (minValue && maxValue) {
            const intervalPartitionPointsInclMinAndMax = [
                minValue - 1,
                ...partitionPoints,
                maxValue,
            ];

            const intervals = [];

            for (
                let i = 0;
                i < intervalPartitionPointsInclMinAndMax.length - 1;
                i++
            ) {
                const start = intervalPartitionPointsInclMinAndMax[i] + 1;
                const end = intervalPartitionPointsInclMinAndMax[i + 1];
                intervals.push({ start, end });
            }
            return intervals;
        }
    }

    const handleAddPartitionPoint = () => {
        const breakPointValue = parseInt(breakPoint, 10);

        if (
            !isNaN(breakPointValue) &&
            breakPointValue != maxValue &&
            !partitionPoints.includes(breakPointValue) &&
            isInRange(breakPointValue, minValue, maxValue)
        ) {
            const newPartitionPoints = [
                ...partitionPoints,
                breakPointValue,
            ].sort((a, b) => a - b);
            const newPointIndex = newPartitionPoints.indexOf(breakPointValue);

            setValue('interval_partition_points', newPartitionPoints);
            setBreakPoint('');

            const existingEdges = Array.isArray(edges) ? edges : [];
            const updatedEdges = existingEdges.map((edge: Edge) => {
                if (edge.source === node.id) {
                    const sourceHandleIndex = parseInt(
                        edge.sourceHandle?.split('_').pop() || '0',
                        10,
                    );

                    if (sourceHandleIndex >= newPointIndex) {
                        // Increase index of handles for edges after the new partition point
                        const newId =
                            edge.id.slice(0, -1) + `${sourceHandleIndex + 1}`;

                        return {
                            ...edge,
                            id: newId,
                            sourceHandle: `${node.id}_${sourceHandleIndex + 1}`,
                        };
                    }
                }
                // Return edges unaffected by the new handle
                return edge;
            });

            const newEdge: Connection = {
                source: node.id,
                sourceHandle: `${node.id}_${newPointIndex}`,
                target: '',
                targetHandle: null,
            };

            setNode(nodeIndex, {
                // @ts-ignore - part of the data type problem
                ...node.data.params,
                interval_partition_points: newPartitionPoints,
            });

            const edgesWithNew = addEdge(newEdge, updatedEdges);

            setEdges(edgesWithNew);
        }

        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const handleMergeIntervals = (point: number) => {
        const removedPointIndex = partitionPoints.indexOf(point);
        const updatedPoints = partitionPoints.filter((p) => p !== point);
        setValue('interval_partition_points', updatedPoints);

        const existingEdges = Array.isArray(edges) ? edges : [];
        const updatedEdges = existingEdges
            .filter((edge) => {
                if (edge.source === node.id) {
                    const sourceHandleIndex = parseInt(
                        edge.sourceHandle?.split('_').pop() || '0',
                        10,
                    );
                    // Remove edges related to the removed handle
                    return sourceHandleIndex !== removedPointIndex;
                }
                // Keep edges that don't belong to the current node
                return true;
            })
            .map((edge) => {
                if (edge.source === node.id) {
                    const sourceHandleIndex = parseInt(
                        edge.sourceHandle?.split('_').pop() || '0',
                        10,
                    );

                    if (sourceHandleIndex > removedPointIndex) {
                        const newId =
                            edge.id.slice(0, -1) + `${sourceHandleIndex + 1}`;
                        // Decrease index of handles for edges after the removed partition point
                        return {
                            ...edge,
                            id: newId,
                            sourceHandle: `${node.id}_${sourceHandleIndex - 1}`,
                        };
                    }
                }
                // Return unaffected edges
                return edge;
            });

        setNode(nodeIndex, {
            // @ts-ignore - part of the data type problem
            ...node.data.params,
            interval_partition_points: updatedPoints,
        });

        setEdges(updatedEdges);
    };

    const onBlurSubmit = (data: Partial<IntervalFormData>) => {
        const { min_value, max_value } = data;
        // @ts-ignore - part of the data type problem
        const updatedParams = { ...node.data.params };

        if (min_value !== undefined) {
            updatedParams.min_value = min_value;
        }

        if (max_value !== undefined) {
            updatedParams.max_value = max_value;
        }

        setNode(nodeIndex, updatedParams);

        // Handle extending existing interval with partitions
        if (
            min_value !== undefined &&
            max_value !== undefined &&
            sortedPartitionPoints.length > 0 &&
            min_value <= sortedPartitionPoints[0] &&
            max_value >=
                sortedPartitionPoints[sortedPartitionPoints.length - 1] + 1
        ) {
            setNode(nodeIndex, {
                ...updatedParams,
                min_value,
                max_value,
            });
        }
    };

    return (
        // find color in palette
        <Box sx={{ padding: 2, background: '#fceaea' }}>
            <Controller
                name='value'
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        label={t('vrr_id_label')}
                        variant='outlined'
                        error={!!errors.value}
                        helperText={errors.value?.message}
                        placeholder={t('vrr_id_placeholder')}
                        fullWidth
                        margin='normal'
                        value={
                            field.value
                                ? field.value.match(
                                      /\{\{user_model\.(.*)\.semantic_value\}\}/,
                                  )?.[1] || ''
                                : ''
                        }
                        onChange={(e) => {
                            const id = e.target.value;
                            const semanticValue = `{{user_model.${id}.semantic_value}}`;
                            field.onChange(semanticValue);

                            setNode(nodeIndex, {
                                // @ts-ignore - part of the data type problem
                                ...node.data.params,
                                value: semanticValue,
                            });
                        }}
                    />
                )}
            />
            <br />
            <Controller
                name='min_value'
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        label='Min'
                        type='number'
                        error={!!errors.min_value}
                        helperText={errors.min_value?.message || ''}
                        margin='normal'
                        fullWidth
                        onBlur={() => onBlurSubmit({ min_value: field.value })}
                    />
                )}
            />

            <Controller
                name='max_value'
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        label='Max'
                        type='number'
                        error={!!errors.max_value}
                        helperText={errors.max_value?.message || ''}
                        margin='normal'
                        fullWidth
                        onBlur={() => onBlurSubmit({ max_value: field.value })}
                    />
                )}
            />
            <TextField
                label={t('vrr_add_breakpoint')}
                type='number'
                inputRef={inputRef}
                value={breakPoint}
                onChange={(e) => setBreakPoint(e.target.value)}
                FormHelperTextProps={{
                    style: { color: 'red' },
                }}
                margin='normal'
                fullWidth
                disabled={minValue === undefined || maxValue === undefined}
            />
            <Button
                type='button'
                variant='contained'
                color='primary'
                onClick={handleAddPartitionPoint}
            >
                {t('vrr_add_breakpoin_btn')}
            </Button>

            <TableContainer
                component={Paper}
                sx={{ marginBottom: 2, marginTop: 2 }}
            >
                <Table
                    sx={{ minWidth: 650, border: '1px solid #ddd' }}
                    aria-label='intervals table'
                >
                    <TableHead>
                        <TableRow
                            sx={{
                                backgroundColor: 'primary',
                                borderBottom: '1px solid #ddd',
                            }}
                        >
                            <TableCell
                                sx={{
                                    borderRight: '1px solid #ddd',
                                    fontWeight: 'bold',
                                }}
                            >
                                {t('vrr_interval_output')}
                            </TableCell>
                            <TableCell
                                sx={{
                                    borderRight: '1px solid #ddd',
                                    fontWeight: 'bold',
                                }}
                            >
                                {t('vrr_interval_start')}
                            </TableCell>
                            <TableCell
                                sx={{
                                    borderRight: '1px solid #ddd',
                                    fontWeight: 'bold',
                                }}
                            >
                                {t('vrr_interval_end')}
                            </TableCell>
                            <TableCell
                                sx={{
                                    borderRight: '1px solid #ddd',
                                    fontWeight: 'bold',
                                }}
                            >
                                {t('vrr_actions')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {computeIntervals()?.map((interval, index) => (
                            <TableRow key={index}>
                                <TableCell
                                    sx={{ borderRight: '1px solid #ddd' }}
                                >
                                    {index + 1}
                                </TableCell>
                                <TableCell
                                    sx={{ borderRight: '1px solid #ddd' }}
                                >
                                    {interval.start}
                                </TableCell>
                                <TableCell
                                    sx={{ borderRight: '1px solid #ddd' }}
                                >
                                    {interval.end}
                                </TableCell>
                                <TableCell
                                    sx={{ borderRight: '1px solid #ddd' }}
                                    style={{ position: 'relative' }}
                                >
                                    {index <
                                    watch('interval_partition_points')
                                        .length ? (
                                        <Button
                                            variant='contained'
                                            color='secondary'
                                            style={{
                                                position: 'absolute',
                                                left: '50%',
                                                transform: 'translateX(-50%)',
                                                top: '33px',
                                                zIndex: 10,
                                            }}
                                            onClick={() =>
                                                handleMergeIntervals(
                                                    watch(
                                                        'interval_partition_points',
                                                    )[index],
                                                )
                                            }
                                        >
                                            {t('merge_vrr_intervals')}
                                        </Button>
                                    ) : null}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <NodeOutputsDetails>
                {computeIntervals()?.map((int, index) => (
                    <NodeOutputDetail
                        key={`${int.start}-${int.end}`}
                        text={`${t('vrr_interval_output')} ${index + 1}${t(
                            'vrr_interval_info_base',
                        )} ${int.start} ${t('vrr_interval_info_end')} ${
                            int.end
                        }${t('vrr_interval_info_incl')}`}
                        dotColor='black'
                    />
                ))}
                <NodeOutputDetail
                    text={t('output_detail_id')}
                    dotColor='#FFA500'
                />
                <NodeOutputDetail
                    text={t('output_detail_min_max')}
                    dotColor='red'
                />
            </NodeOutputsDetails>
        </Box>
    );
};

import { ImmerStateCreator } from './telma-portal-store';

export interface LayoutSlice {
    opened: boolean;
    setMenu: () => void;
}

export const layoutSlice: ImmerStateCreator<LayoutSlice> = (set) => ({
    opened: true,
    setMenu: () =>
        set((state) => {
            state.opened = !state.opened;
        }),
});

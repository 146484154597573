import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BotContentTextField } from '@/components/custom-form-text-field';
import { NodeFormProps } from '@/domain/node-form-props';
import { useTelmaPortalStore } from '@/store/telma-portal-store';
import { useSaveBotVersionEnabled } from '@/utils/use-save-bot-version-enabled';

export const AlternativeYesNoPhrasesBlock: FC<NodeFormProps> = ({
    nodeIndex,
    control,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const saveEnabled = useSaveBotVersionEnabled();

    const node = useTelmaPortalStore(
        (state) => state.editingBot.nodesReactFlow[nodeIndex],
    );

    return (
        <Box
            sx={{
                border: `solid 3px ${theme.palette.secondary.veryLight}`,
                borderRadius: '10px',
                p: 2,
                marginBottom: '1rem',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                }}
            >
                <Typography variant='cardTitle'>
                    {t('alternative_user_input')}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                }}
            >
                <Box sx={{ width: '33%' }}>
                    <Typography className='input-label-card'>
                        {t('extra_yes_phrases_info')}
                    </Typography>
                    <Controller
                        name='yes_alternatives'
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <BotContentTextField
                                {...field}
                                fieldError={error}
                                placeholder={
                                    t('extra_yes_phrases_example') as string
                                }
                                multiline
                                rows={3}
                                fullWidth
                                autoResize
                                disabled={!saveEnabled}
                            />
                        )}
                    />
                </Box>
                <Box sx={{ width: '33%' }}>
                    <Typography className='input-label-card'>
                        {t('extra_no_phrases_info')}
                    </Typography>
                    <Controller
                        name='no_alternatives'
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <BotContentTextField
                                {...field}
                                fieldError={error}
                                placeholder={
                                    t('extra_no_phrases_example') as string
                                }
                                multiline
                                rows={3}
                                fullWidth
                                autoResize
                                disabled={!saveEnabled}
                            />
                        )}
                    />
                </Box>
                {
                    //@ts-ignore - data type problem
                    node.data.params.escape_mode !== 'undefined' && (
                        <Box sx={{ width: '33%' }}>
                            <Typography className='input-label-card'>
                                {t('extra_idontkonow_phrases_info')}
                            </Typography>

                            <Controller
                                name='escape_alternatives'
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <BotContentTextField
                                        {...field}
                                        fieldError={error}
                                        placeholder={
                                            t(
                                                'extra_idontkonow_phrases_example',
                                            ) as string
                                        }
                                        multiline
                                        rows={3}
                                        fullWidth
                                        autoResize
                                        disabled={!saveEnabled}
                                    />
                                )}
                            />
                        </Box>
                    )
                }
            </Box>
        </Box>
    );
};

import { yupResolver } from '@hookform/resolvers/yup';
import {
    Box,
    Checkbox,
    FormControlLabel,
    Grid,
    MenuItem,
    Select,
} from '@mui/material';
import { useSession } from 'next-auth/react';
import { FC, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { shallow } from 'zustand/shallow';

import {
    BotContentTextField,
    FormTextFieldWithRef,
} from '../custom-form-text-field';

import { BaseNodeForm } from './base-node-form';

import { SMSModuleBotModuleData } from '@/domain/bot';
import { NodeFormProps } from '@/domain/node-form-props';
import { PhoneValidationSchemaFields } from '@/schemas/phone-validation-schema';
import { useTelmaPortalStore } from '@/store/telma-portal-store';
import { useSaveBotVersionEnabled } from '@/utils/use-save-bot-version-enabled';

export const botSMSModuleNodeSchema = yup.object({
    prompt: yup.string().max(500),
    isCustomPhone: yup.bool(),
    phonePrefix: yup.string().when('isCustomPhone', {
        is: true,
        then: () => PhoneValidationSchemaFields.phonePrefix,
    }),
    phoneNumber: yup.string().when('isCustomPhone', {
        is: true,
        then: () => PhoneValidationSchemaFields.phoneNumber,
    }),
});

export const SMSModuleNodeForm: FC<NodeFormProps> = ({ nodeIndex }) => {
    const [setNode, node] = useTelmaPortalStore(
        (state) => [state.setNode, state.editingBot.nodesReactFlow[nodeIndex]],
        shallow,
    );

    const { data: session } = useSession();
    const saveEnabled = useSaveBotVersionEnabled();

    //we should probably not display anything in case session is missing
    const allowedPrefixesOptions = session?.user?.allowedPrefixesOptions ?? [];
    const [isAttemptingSubmit, setIsAttemptingSubmit] = useState(
        //@ts-ignore - data type problem
        node.data.params.isCustomPhone,
    );

    const { handleSubmit, control, setValue, watch } = useForm<
        SMSModuleBotModuleData['params']
    >({
        resolver: yupResolver(botSMSModuleNodeSchema),
        defaultValues: {
            phonePrefix: allowedPrefixesOptions[0]?.value ?? '',
            phoneNumber: '',
            //@ts-ignore - data type problem
            ...node?.data?.params,
        },
        context: {
            isAttemptingSubmit: isAttemptingSubmit,
            isForm: true,
            regexAllowedPrefixes: new RegExp(
                allowedPrefixesOptions.reduce((result, option, index) => {
                    if (index === 0) {
                        return `${result}`;
                    }
                    return `${result}|${option.value}`;
                }, allowedPrefixesOptions[0]?.value),
            ),
        },
    });

    const { t } = useTranslation();
    const isCustomPhone = watch('isCustomPhone');

    const botNodeSubmit: SubmitHandler<SMSModuleBotModuleData['params']> = (
        formData,
    ) => {
        setNode(nodeIndex, formData);
    };

    const handleBlur = async (value: string | undefined) => {
        try {
            await botSMSModuleNodeSchema.validateAt('phoneNumber', {
                phoneNumber: value,
            });
            setValue('phoneNumber', value, {
                shouldValidate: true,
                shouldDirty: true,
            });
        } catch (validationError) {}
    };

    return (
        <BaseNodeForm nodeId={node.id} nodeTitle={node.type}>
            <form onBlur={handleSubmit(botNodeSubmit)}>
                <Controller
                    name='prompt'
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <BotContentTextField
                            {...field}
                            fieldError={error}
                            autoResize
                            autoFocus
                            disabled={!saveEnabled}
                        />
                    )}
                />

                <Box sx={{ mt: 2 }}>
                    <Controller
                        control={control}
                        name='isCustomPhone'
                        render={({ field: { value, ref } }) => (
                            <FormControlLabel
                                label={t('use_different_phone')}
                                control={
                                    <Checkbox
                                        onChange={(e) => {
                                            setValue(
                                                'isCustomPhone',
                                                e.target.checked,
                                            );
                                            setIsAttemptingSubmit(
                                                e.target.checked,
                                            );
                                        }}
                                        checked={value}
                                        inputRef={ref}
                                        disabled={!saveEnabled}
                                    />
                                }
                            />
                        )}
                    />
                </Box>

                <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={4}>
                        <Controller
                            name='phonePrefix'
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <Select
                                    {...field}
                                    onChange={(e) => {
                                        setValue('phonePrefix', e.target.value);
                                    }}
                                    fullWidth
                                    disabled={!isCustomPhone || !saveEnabled}
                                >
                                    {allowedPrefixesOptions.map(
                                        (prefix: {
                                            value: string;
                                            countryCode: string;
                                        }) => (
                                            <MenuItem
                                                key={prefix.countryCode}
                                                value={prefix.value}
                                            >
                                                {`${prefix.countryCode} +${prefix.value}`}
                                            </MenuItem>
                                        ),
                                    )}
                                </Select>
                            )}
                        />
                    </Grid>

                    <Grid item xs={8}>
                        <Controller
                            control={control}
                            name='phoneNumber'
                            rules={{ required: isCustomPhone }}
                            render={({
                                field: { ref, ...rest },
                                fieldState: { error },
                            }) => (
                                <FormTextFieldWithRef
                                    fullWidth
                                    {...rest}
                                    onBlur={async () => handleBlur(rest.value)}
                                    fieldError={error}
                                    disabled={!isCustomPhone || !saveEnabled}
                                    ref={ref}
                                    placeholder={t('phone_number') as string}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </form>
        </BaseNodeForm>
    );
};

/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        // lng: 'cs',
        react: {
            useSuspense: true,
        },
        interpolation: {
            escapeValue: false,
        },
        detection: {
            order: [
                'localStorage',
                'navigator',
                // 'htmlTag',
                // 'querystring',
                // 'cookie',
                // 'sessionStorage',
                // 'path',
                // 'subdomain',
            ],
            // cache user language on
            caches: ['localStorage'],
        },
    });

export default i18n;

import { CSSObject } from '@emotion/react';
import { Box, Drawer, useMediaQuery } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import Image from 'next/image';
import { FC } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { shallow } from 'zustand/shallow';

import { LanguageSection } from '../header/language-section';
import { ProfileSection } from '../header/profile-section';

import { MenuList } from './menu-list';

import Mouthwave from '@/assets/images/Mouthwave.svg';
import { DrawerButton } from '@/components/button/icon-button';
import { useTelmaPortalStore } from '@/store/telma-portal-store';

// ==============================|| SIDEBAR DRAWER ||============================== //

const openedMixin = (theme: Theme): CSSObject => ({
    // @ts-ignore
    width: theme.mixins.drawer.width,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

export const Sidebar: FC = () => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const [opened, setMenu] = useTelmaPortalStore(
        (state) => [state.opened, state.setMenu],
        shallow,
    );
    const isNarrowBar = !opened && matchUpMd;

    const drawer = (
        <>
            <BrowserView>
                <PerfectScrollbar
                    component='div'
                    style={{
                        boxSizing: 'border-box',
                        background: theme.palette.primary.main,
                        color: 'white',
                        height: isNarrowBar
                            ? '100vh'
                            : !matchUpMd
                            ? 'calc(100vh - 56px)'
                            : 'calc(100vh - 80px)',
                        paddingLeft: isNarrowBar ? undefined : '16px',
                        paddingRight: isNarrowBar ? undefined : '16px',
                        //@ts-ignore
                        width: `${theme.mixins.drawer.width}px`,
                    }}
                >
                    {isNarrowBar && (
                        <Box sx={{ p: 1, pl: 1.5 }}>
                            <DrawerButton />
                        </Box>
                    )}
                    <Box
                        component='div'
                        sx={{
                            p: isNarrowBar ? 1.5 : 2,
                            textAlign: isNarrowBar ? undefined : 'center',
                        }}
                    >
                        <Image
                            src={Mouthwave}
                            width={
                                isNarrowBar
                                    ? 40
                                    : //@ts-ignore
                                      theme.mixins.drawer.width - 100
                            }
                            alt='Telma logo'
                            priority={true}
                        />
                        {!isNarrowBar && (
                            <h1 style={{ fontSize: '2rem' }}>TelmaPortal</h1>
                        )}
                    </Box>
                    <MenuList isNarrowBar={isNarrowBar} />

                    {isNarrowBar && (
                        <>
                            <Box sx={{ mt: 5 }} />
                            <LanguageSection isNarrowBar={isNarrowBar} />
                            <ProfileSection isNarrowBar={isNarrowBar} />
                        </>
                    )}
                </PerfectScrollbar>
            </BrowserView>
            <MobileView>
                <Box sx={{ px: 2 }}>
                    <MenuList isNarrowBar={isNarrowBar} />
                </Box>
            </MobileView>
        </>
    );

    return (
        <Box
            component='nav'
            sx={{
                flexShrink: { md: 0 },
                width: '100%',
            }}
            aria-label='mailbox folders'
        >
            <Drawer
                variant={matchUpMd ? 'permanent' : 'temporary'}
                anchor='left'
                open={opened}
                onClose={setMenu}
                sx={
                    matchUpMd
                        ? {
                              ...(opened && {
                                  ...openedMixin(theme),
                                  '& .MuiDrawer-paper': {
                                      ...openedMixin(theme),
                                      top: '80px',
                                  },
                              }),
                              ...(!opened && {
                                  ...closedMixin(theme),
                                  '& .MuiDrawer-paper': {
                                      ...closedMixin(theme),
                                  },
                                  top: '80px',
                              }),
                          }
                        : {
                              '& .MuiDrawer-paper': {
                                  background: theme.palette.background.default,
                                  color: theme.palette.text.primary,
                                  borderRight: 'none',
                              },
                          }
                }
                ModalProps={{ keepMounted: true }}
                color='inherit'
            >
                {drawer}
            </Drawer>
        </Box>
    );
};

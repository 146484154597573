import { Box, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSession } from 'next-auth/react';
import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BotContentTextField } from '@/components/custom-form-text-field';
import { ESCAPE_MODE_OPTIONS } from '@/data/escape-mode-options';
import { NodeFormProps } from '@/domain/node-form-props';
import { useTelmaPortalStore } from '@/store/telma-portal-store';
import { useSaveBotVersionEnabled } from '@/utils/use-save-bot-version-enabled';

export const QuestionAndAnswersBlock: FC<NodeFormProps> = ({
    nodeIndex,
    control,
    setValue,
}) => {
    const { t } = useTranslation();
    const { data: session } = useSession();
    const theme = useTheme();
    const saveEnabled = useSaveBotVersionEnabled();

    const node = useTelmaPortalStore(
        (state) => state.editingBot.nodesReactFlow[nodeIndex],
    );

    const onEdgesFromHandleRemove = useTelmaPortalStore(
        (state) => state.onEdgesFromHandleRemove,
    );

    return (
        <Box
            sx={{
                border: `solid 3px ${theme.palette.secondary.veryLight}`,
                borderRadius: '10px',
                p: 2,
                marginBottom: '1rem',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                }}
            >
                <Typography variant='cardTitle'>
                    {t('question_and_answers')}
                </Typography>
            </Box>

            <Box sx={{ marginBottom: '10px' }}>
                <InputLabel className='input-label-card'>
                    {t('question')}
                </InputLabel>
                <Controller
                    name='question'
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <BotContentTextField
                            {...field}
                            fieldError={error}
                            fullWidth
                            autoResize
                            autoFocus
                            disabled={!saveEnabled}
                        />
                    )}
                />
            </Box>

            <Box sx={{ marginBottom: '10px' }}>
                <Controller
                    name='escape_mode'
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <Select
                            disabled={
                                session?.user?.roles?.isFreemium || !saveEnabled
                            }
                            {...field}
                            onChange={(e) => {
                                if (setValue) {
                                    setValue('escape_mode', e.target.value);
                                    if (
                                        e.target.value === 'undefined' ||
                                        e.target.value === 'no_escape'
                                    ) {
                                        onEdgesFromHandleRemove([
                                            `${node.id}_S`,
                                        ]);
                                    }
                                }
                            }}
                            fullWidth
                        >
                            {ESCAPE_MODE_OPTIONS.map((option) => (
                                <MenuItem value={option} key={option}>
                                    {t(`escape-mode-options.${option}`)}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                />
            </Box>

            <Box sx={{ display: 'flex', gap: 2, marginBottom: '10px' }}>
                <Box>
                    <InputLabel className='input-label-card'>
                        {t('response_to_yes')}
                    </InputLabel>

                    <Controller
                        name='prompt_yes_acknowledgment'
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <BotContentTextField
                                {...field}
                                fieldError={error}
                                fullWidth
                                autoResize
                                disabled={!saveEnabled}
                            />
                        )}
                    />

                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <div
                            className='node-section-port-indication'
                            style={{ backgroundColor: 'green' }}
                        />
                    </Box>
                </Box>

                <Box>
                    <InputLabel className='input-label-card'>
                        {t('response_to_no')}
                    </InputLabel>

                    <Controller
                        name='prompt_no_acknowledgment'
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <BotContentTextField
                                {...field}
                                fieldError={error}
                                fullWidth
                                autoResize
                                disabled={!saveEnabled}
                            />
                        )}
                    />

                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <div
                            className='node-section-port-indication'
                            style={{ backgroundColor: 'red' }}
                        />
                    </Box>
                </Box>
                {
                    //@ts-ignore - data type problem
                    node.data.params.escape_mode !== 'undefined' && (
                        <Box>
                            <InputLabel className='input-label-card'>
                                {t('response_to_escape')}
                            </InputLabel>
                            <Controller
                                name='prompt_escape_acknowledgment'
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <BotContentTextField
                                        {...field}
                                        fieldError={error}
                                        fullWidth
                                        autoResize
                                        disabled={!saveEnabled}
                                    />
                                )}
                            />

                            {
                                //@ts-ignore - data type problem
                                node.data.params.escape_mode !==
                                    'undefined' && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <div
                                            className='node-section-port-indication'
                                            style={{ backgroundColor: 'blue' }}
                                        />
                                    </Box>
                                )
                            }
                        </Box>
                    )
                }
            </Box>
            <Box>
                <InputLabel className='input-label-card'>
                    {t('prompt_no_match')}
                </InputLabel>

                <Controller
                    name='prompt_no_match'
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <BotContentTextField
                            {...field}
                            fieldError={error}
                            placeholder={
                                t('you_will_override_default') as string
                            }
                            fullWidth
                            autoResize
                            disabled={!saveEnabled}
                        />
                    )}
                />
            </Box>
        </Box>
    );
};

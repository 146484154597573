import { Logout, Settings } from '@mui/icons-material';
import {
    Avatar,
    Box,
    Chip,
    ClickAwayListener,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Stack,
    styled,
    Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useRouter } from 'next/router';
import { signOut, useSession } from 'next-auth/react';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import MainCard from '@/components/cards/main-card';
import { Transitions } from '@/components/extended/transitions';

const ListItemButtonProfile = styled(ListItemButton)<any>({
    color: 'black',
});

// ==============================|| PROFILE MENU ||============================== //

export const ProfileSection: FC<{ isNarrowBar: boolean }> = ({
    isNarrowBar,
}) => {
    const theme: any = useTheme();
    const router = useRouter();
    const { data: session } = useSession();
    const [open, setOpen] = useState<boolean>(false);
    const [role, setRole] = useState<string>('Undefined');
    const prevOpen = useRef<boolean>(open);
    const { t } = useTranslation();

    useEffect(() => {
        if (session) {
            session?.user?.roles?.isTester
                ? setRole('Tester')
                : setRole('Project admin');
        }
    }, [session]);

    /**
     * anchorRef must remain as <any> because it's used on different components and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef<any>(null);

    const handleLogout = () => {
        signOut();
    };

    const handleClose = (event: MouseEvent | TouchEvent | React.MouseEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        route = '',
    ) => {
        handleClose(event);

        if (route && route !== '') {
            router.push(route);
        }
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const stringAvatar = (name: string) => {
        return name.split(' ')[0][0].toUpperCase();
    };

    return (
        <>
            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.primary.light,
                    backgroundColor: theme.palette.primary.light,
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.primary.dark,
                        background: `${theme.palette.primary.main}!important`,
                        color: theme.palette.primary.light,
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0,
                    },
                    marginTop: isNarrowBar ? '8px' : '0',
                    marginLeft: isNarrowBar ? '7px' : '0',
                }}
                avatar={
                    isNarrowBar ? undefined : (
                        <Avatar
                            sx={{
                                ...theme.typography.mediumAvatar,
                                margin: '8px 0 8px 8px !important',
                                cursor: 'pointer',
                            }}
                            ref={anchorRef}
                            aria-controls={open ? 'menu-list-grow' : undefined}
                            aria-haspopup='true'
                            color='inherit'
                        >
                            {session?.user?.name
                                ? stringAvatar(session.user.name)
                                : 'U'}
                        </Avatar>
                    )
                }
                label={<Settings color={theme.palette.primary.main} />}
                variant='outlined'
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup='true'
                onClick={handleToggle}
                color='primary'
            />
            <Popper
                placement={isNarrowBar ? 'left-start' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal={!isNarrowBar}
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 10],
                            },
                        },
                    ],
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard
                                    border={false}
                                    elevation={16}
                                    content={false}
                                    boxShadow
                                    shadow={theme.shadows[16]}
                                >
                                    <Box sx={{ p: 2 }}>
                                        <Stack>
                                            <Stack
                                                direction='row'
                                                spacing={0.5}
                                                alignItems='center'
                                            >
                                                <Typography variant='h4'>
                                                    {t('good_day')},
                                                </Typography>
                                                <Typography
                                                    component='span'
                                                    variant='h4'
                                                    sx={{ fontWeight: 400 }}
                                                >
                                                    {session
                                                        ? session.user!.name
                                                        : 'User'}
                                                </Typography>
                                            </Stack>
                                            <Typography variant='subtitle2'>
                                                {role}
                                            </Typography>
                                        </Stack>
                                    </Box>
                                    <Divider variant='middle' />
                                    <Box sx={{ p: 2 }}>
                                        <List
                                            component='nav'
                                            sx={{
                                                width: '100%',
                                                maxWidth: 350,
                                                minWidth: 300,
                                                backgroundColor:
                                                    theme.palette.background
                                                        .paper,
                                                padding: 0,
                                            }}
                                        >
                                            {/* 
                                            <ListItemButtonProfile
                                                sx={{
                                                    borderRadius: `${theme.borderRadius}px`,
                                                }}
                                                onClick={(event: any) =>
                                                    handleListItemClick(
                                                        event,
                                                        '/user/account-profile/profile1',
                                                    )
                                                }
                                            >
                                                <ListItemIcon>
                                                    <Settings />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={
                                                        <Typography variant='menuItemDark'>
                                                            {t(
                                                                'account_settings',
                                                            )}
                                                        </Typography>
                                                    }
                                                />
                                            </ListItemButtonProfile> 
                                            */}
                                            <ListItemButtonProfile
                                                sx={{
                                                    borderRadius: `${theme.borderRadius}px`,
                                                }}
                                                onClick={handleLogout}
                                            >
                                                <ListItemIcon>
                                                    <Logout />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={
                                                        <Typography variant='menuItemDark'>
                                                            {t('logout')}
                                                        </Typography>
                                                    }
                                                />
                                            </ListItemButtonProfile>
                                        </List>
                                    </Box>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

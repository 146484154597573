// material-ui
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FC, forwardRef } from 'react';

// constant
const headerSX = {
    '& .MuiCardHeader-action': { mr: 0 },
};

// ==============================|| CUSTOM MAIN CARD ||============================== //

const MainCard: FC<any> = forwardRef(
    (
        {
            border = true,
            boxShadow,
            children,
            content = true,
            contentClass = '',
            contentSX = {},
            darkTitle,
            secondary,
            shadow,
            sx = {},
            title,
            ...others
        }: any,
        ref,
    ) => {
        const theme: any = useTheme();

        return (
            <Card
                ref={ref}
                {...others}
                sx={{
                    border: border ? '1px solid' : 'none',
                    borderColor: theme.palette.primary.main,
                    ':hover': {
                        boxShadow: boxShadow
                            ? shadow || '0 2px 14px 0 rgb(32 40 45 / 8%)'
                            : 'inherit',
                    },
                    ...sx,
                }}
            >
                {/* card header and action */}
                {title && (
                    <CardHeader
                        sx={headerSX}
                        title={
                            darkTitle ? (
                                <Typography variant='h3'>{title}</Typography>
                            ) : (
                                title
                            )
                        }
                        action={secondary}
                    />
                )}

                {/* content & header divider */}
                {title && <Divider />}

                {/* card content */}
                {content && (
                    <CardContent sx={contentSX} className={contentClass}>
                        {children}
                    </CardContent>
                )}
                {!content && children}
            </Card>
        );
    },
);

MainCard.displayName = 'MainCard';

export default MainCard;

import { Box, InputLabel, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BotContentTextField } from '@/components/custom-form-text-field';
import { NodeFormProps } from '@/domain/node-form-props';
import { useSaveBotVersionEnabled } from '@/utils/use-save-bot-version-enabled';

export const RatingDialogueBlock: FC<NodeFormProps> = ({ control }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const saveEnabled = useSaveBotVersionEnabled();

    return (
        <Box
            sx={{
                border: `solid 3px ${theme.palette.secondary.veryLight}`,
                borderRadius: '10px',
                p: 2,
                marginBottom: '1rem',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                }}
            >
                <Typography variant='cardTitle'>
                    {t('rating_dialogue')}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'baseline',
                    marginBottom: '10px',
                }}
            >
                <Box sx={{ marginBottom: '10px', width: '100%' }}>
                    <InputLabel className='input-label-card'>
                        {t('question')}
                    </InputLabel>
                    <Controller
                        name='prompt'
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <BotContentTextField
                                {...field}
                                fieldError={error}
                                fullWidth
                                autoResize
                                disabled={!saveEnabled}
                            />
                        )}
                    />
                </Box>
                <Typography>{t('range_variables_info')}</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <Box sx={{ width: '50%' }}>
                    <InputLabel
                        className='input-label-card'
                        sx={{ marginBottom: '10px' }}
                    >
                        {t('numeric_rating_found_prompt')}
                    </InputLabel>
                    <Controller
                        name='found_prompt'
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <BotContentTextField
                                {...field}
                                fieldError={error}
                                fullWidth
                                autoResize
                                disabled={!saveEnabled}
                            />
                        )}
                    />
                </Box>

                <Box sx={{ width: '50%' }}>
                    <InputLabel
                        className='input-label-card'
                        sx={{ marginBottom: '10px' }}
                    >
                        {t('numeric_rating_not_found_prompt')}
                    </InputLabel>
                    <Controller
                        name='not_found_prompt'
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <BotContentTextField
                                {...field}
                                fieldError={error}
                                fullWidth
                                autoResize
                                disabled={!saveEnabled}
                            />
                        )}
                    />
                </Box>
            </Box>
        </Box>
    );
};

import { platform } from 'os';

import _ from 'lodash';
import { useCallback, useEffect } from 'react';

function isMac() {
    // eslint-disable-next-line
    // @ts-ignore
    if (platform() !== 'browser') {
        return false;
    }
    const navigatorPlatform =
        // eslint-disable-next-line
        // @ts-ignore
        navigator?.platform || navigator?.userAgentData?.platform || 'unknown';
    return navigatorPlatform.toUpperCase().indexOf('MAC') >= 0;
}

export const keyboardShortcuts = {
    undo: 'CtrlOrCommand+Z',
    redo: isMac() ? 'Command+Shift+Z' : 'Ctrl+Y',
};

function formatKeyText(keyText: string): string {
    if (isMac()) {
        return keyText
            .replace('CtrlOrCommand+', '⌘ ')
            .replace('Command+', '⌘ ')
            .replace('Ctrl+', '⌃ ')
            .replace('Shift+', '⇧ ')
            .replace('Alt+', '⌥ ')
            .replace('Backspace', '⌫ ');
    }
    return keyText.replace('CtrlOrCommand+', 'Ctrl+');
}

export const keyboardShortcutsFormatted = _.mapValues(keyboardShortcuts, (v) =>
    formatKeyText(v),
);

const keyboardShortcutsCommands = _.fromPairs(
    Object.entries(keyboardShortcuts).map(([cmd, key]) => [
        resolveKeyText(key).toUpperCase(),
        cmd,
    ]),
);

// console.log("keyboardShortcutsCommands", keyboardShortcutsCommands);

const mappedCommands: {
    [field in keyof typeof keyboardShortcuts]: (() => void)[];
} = _.mapValues(keyboardShortcuts, () => []);

export function resolveKeyText(keyText: string): string {
    if (isMac()) {
        return keyText.replace('CtrlOrCommand+', 'Command+');
    }
    return keyText.replace('CtrlOrCommand+', 'Ctrl+');
}

export function useKeyboardListener() {
    const handleKeyDown = useCallback((e: KeyboardEvent) => {
        let keyText = '';
        if (e.ctrlKey) keyText += 'Ctrl+';
        if (e.metaKey) keyText += 'Command+';
        if (e.shiftKey) keyText += 'Shift+';
        if (e.altKey) keyText += 'Alt+';
        keyText += e.key;

        const command = keyboardShortcutsCommands[keyText.toUpperCase()] as
            | keyof typeof keyboardShortcuts
            | undefined;

        // console.log(`Key down: ${keyText}`);

        if (command) {
            console.log(`Handling keyboard ${keyText} event: ${command}`);

            const fns = mappedCommands[command];
            if (fns.length == 1) {
                fns[0]();

                e.preventDefault();
                e.stopPropagation();
            } else if (fns.length > 0) {
                console.error(
                    `Command ${command} mapped more than once, skipping`,
                );
            }
        }
    }, []);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);
}

export function useDefineCommand(
    commands: Partial<{
        [field in keyof typeof keyboardShortcuts]: (() => void) | undefined;
    }>,
) {
    useEffect(
        () => {
            for (const key of Object.keys(
                commands,
            ) as (keyof typeof keyboardShortcuts)[]) {
                const cmd = commands[key];
                if (cmd) {
                    mappedCommands[key] = [...mappedCommands[key], cmd];
                }
            }
            return () => {
                for (const key of Object.keys(
                    commands,
                ) as (keyof typeof keyboardShortcuts)[]) {
                    if (commands[key]) {
                        mappedCommands[key] = mappedCommands[key].filter(
                            (x) => x != commands[key],
                        );
                    }
                }
            };
        },
        Object.keys(keyboardShortcuts).map(
            (cmd) => commands[cmd as keyof typeof keyboardShortcuts],
        ),
    );
}

import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import { FC } from 'react';
import { FieldError } from 'react-hook-form';

export const StyledTextareaAutoresize = styled(TextareaAutosize)<{
    fullWidth: boolean;
    error: FieldError;
}>(({ fullWidth, error }) => {
    const isError = !!error;
    return `font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    background-color: #f8fafc;
    padding: 15.5px 14px;
    border-radius: 4px 4px 0 4px;
    border-width: 1px;
    ${fullWidth ? 'width: 100%;' : ''}
    
    box-sizing: border-box;

    border-color: ${isError ? 'rgb(244, 67, 54)' : '#BBB'} ;
    color: #364152;
  
    &:hover {
      border-color: #000;
    }
  
    &:focus {
      border-width: 2px;
      border-color: ${isError ? 'rgb(244, 67, 54)' : '#000055'};
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #cccccc;
      opacity: 1; /* Firefox */
    }`;
}) as FC<any>;

import { Box, Typography } from '@mui/material';
import React from 'react';

interface NodeOutputDetailProps {
    text: string;
    dotColor: string;
}

export const NodeOutputDetail: React.FC<NodeOutputDetailProps> = ({
    text,
    dotColor,
}) => {
    return (
        <Box display='flex' alignItems='center' mb={2}>
            <Box flex={1} sx={{ wordWrap: 'break-word', maxWidth: '80%' }}>
                <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                    {text}
                </Typography>
            </Box>

            <Box
                sx={{
                    width: 10,
                    height: 10,
                    bgcolor: dotColor,
                    borderRadius: '50%',
                    marginLeft: 'auto', // Push the dot to the far right
                    flexShrink: 0,
                }}
            />
        </Box>
    );
};

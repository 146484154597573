import { Node } from 'reactflow';

import { NodeType } from '@/domain/bot';

export const MINIMAL_NODES: Node[] = [
    {
        id: 'start',
        type: 'StartNode',
        position: { x: 0, y: 0 },
        data: { type: 'Start' as NodeType },
    },
    {
        id: 'start',
        type: 'ExitNode',
        position: { x: 0, y: 0 },
        data: { type: 'Exit' as NodeType },
    },
];

// const MINIMAL_EDGES: Edge[] = [
//     {
//         id: 'start_end_E',
//         source: 'start',
//         sourceHandle: 'start_E',
//         target: 'end',
//         type: 'CustomDeletableEdge',
//     },
// ];

// text property of node is not updated through any form

import type { Node } from 'reactflow';

import {
    BotModuleData,
    CalendarModuleBotModuleData,
    CustomInternalModuleBotModuleData,
    ExitModuleData,
    ModuleSource,
    MSearchModuleBotModuleData,
    NumericRatingBotModuleData,
    OpenQuestionBotModuleData,
    PromptBotModuleData,
    RestCalloutData,
    SMSModuleBotModuleData,
    StickersBotModuleData,
    ValueRangeRouterBotModuleData,
    YesNoConfirmationBotModuleData,
} from '@/domain/bot';
import { PhonePrefixRegex } from '@/schemas/phone-validation-schema';

function parseCustomPhoneNumber(number?: string) {
    if (!number) {
        return null;
    }

    const match = number.match(PhonePrefixRegex);

    if (match?.index === 0) {
        return {
            isCustomPhone: true,
            phonePrefix: match[0],
            phoneNumber: number.substring(match[0].length),
        };
    }

    return null;
}

/**
 * Creates node in flow graph
 * @param id - unique id of node
 * @param type - one of BotMappieModulePresetType or string naming any CustomInternalModule
 * @param params - node.data params
 * @param source - node.data source
 * @returns object representing node in flow graph
 */

export const createNodeReactFlow = (
    id: string,
    type: string,
    params?: { [key: string]: string | string[] | number },
    source?: ModuleSource,
    xPosition?: number,
    yPosition?: number,
): Node<BotModuleData> => {
    const basicNode = {
        id: id,
        type: `${type}Node`,
        position: { x: xPosition ?? 0, y: yPosition ?? 0 },
    };

    const basicData = {
        type: type,
        valid: true,
    };

    switch (type) {
        case 'Prompt':
            return {
                ...basicNode,
                data: {
                    ...basicData,
                    params: params ?? { prompt: '' },
                } as PromptBotModuleData,
            };

        case 'OpenQuestion':
            return {
                ...basicNode,
                data: {
                    ...basicData,
                    params: params ?? {
                        question: '',
                        acknowledgment_prompt: '',
                    },
                } as OpenQuestionBotModuleData,
            };

        case 'SMSModule':
            return {
                ...basicNode,
                data: {
                    ...basicData,
                    params: params
                        ? {
                              ...params,
                              ...parseCustomPhoneNumber(
                                  params.target_sms_number as string,
                              ),
                              target_sms_number: undefined,
                          }
                        : {
                              prompt: '',
                              isCustomPhone: false,
                          },
                } as SMSModuleBotModuleData,
            };

        case 'YesNoConfirmation':
            return {
                ...basicNode,
                data: {
                    ...basicData,
                    params: params
                        ? {
                              ...params,
                              escape_mode: String(params?.escape_mode),
                          }
                        : {
                              question: '',
                              prompt_yes_acknowledgment: '',
                              prompt_no_acknowledgment: '',
                              yes_alternatives: '',
                              no_alternatives: '',
                              number_of_retries: 2,
                              prompt_fallback: '',
                              escape_mode: 'undefined',
                          },
                } as YesNoConfirmationBotModuleData,
            };

        case 'GPTModule':
            return {
                ...basicNode,
                data: {
                    ...basicData,
                    params: params
                        ? {
                              ...params,
                          }
                        : {
                              question: '',
                              prompt_yes_acknowledgment: '',
                              prompt_no_acknowledgment: '',
                              yes_alternatives: '',
                              no_alternatives: '',
                              number_of_retries: 2,
                              prompt_fallback: '',
                              escape_mode: 'undefined',
                          },
                } as YesNoConfirmationBotModuleData,
            };

        case 'NumericRatingModule':
            return {
                ...basicNode,
                data: {
                    ...basicData,
                    params: params ?? {
                        min: 0,
                        max: 0,
                        retries: 0,
                        prompt: '',
                        out_of_bounds_prompt: '',
                        not_found_prompt: '',
                        found_prompt: '',
                        try_again_prompt: '',
                        max_retries_reached_prompt: '',
                    },
                } as NumericRatingBotModuleData,
            };

        case 'ValueRangeRouter':
            return {
                ...basicNode,
                data: {
                    ...basicData,
                    params: params ?? {
                        value: '',
                        min_value: undefined,
                        max_value: undefined,
                        interval_partition_points: [],
                    },
                } as ValueRangeRouterBotModuleData,
            };

        case 'StickerModule':
            return {
                ...basicNode,
                data: {
                    ...basicData,
                    params: params ?? {
                        stickers: ['S1'],
                    },
                } as StickersBotModuleData,
            };

        case 'CalendarModule':
            return {
                ...basicNode,
                data: {
                    ...basicData,
                    source: {
                        path: 'mama_bots.bots.calendar_bot.calendar_module',
                        project: 'maddie',
                    },
                } as CalendarModuleBotModuleData,
            };

        // creating custom internal module de novo
        case 'CustomInternalModule':
            return {
                ...basicNode,
                data: {
                    ...basicData,
                    params: params ?? {
                        moduleType: '',
                    },
                    source: source ?? {
                        project: '',
                        path: '',
                    },
                } as CustomInternalModuleBotModuleData,
            };

        case 'MSearchModule':
            return {
                ...basicNode,
                data: {
                    ...basicData,
                    params: params ?? {
                        collection_id: '',
                        search_mode: 'hybrid',
                        ggr_provider: {},
                        ggr_llm_config: {
                            provider: 'azure',
                            service: 'llm1-themamaai',
                            engine: 'gpt35turbo',
                        },
                        mapping: {},
                    },
                } as MSearchModuleBotModuleData,
            };

        case 'RestCallout':
            return {
                ...basicNode,
                data: {
                    ...basicData,
                    params: params ?? {
                        // request_template: '',
                        request_template: {
                            method: 'GET',
                            base_url: 'https://domain.com',
                            endpoint: '/endpoint',
                        },
                    },
                } as RestCalloutData,
            };

        case 'Exit':
            return {
                ...basicNode,
                data: {
                    ...basicData,
                } as ExitModuleData,
            };

        default:
            //loading custom module form specification
            return {
                id: id,
                type: 'CustomInternalModuleNode',
                data: {
                    type: 'CustomInternalModule',
                    valid: true,
                    params: {
                        moduleType: type,
                    },
                    source: source ?? {
                        project: '',
                        path: '',
                    },
                },
                position: { x: 0, y: 0 },
            };
    }
};

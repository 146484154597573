import {
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    useTheme,
} from '@mui/material';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Item, MENU_OPTIONS } from '@/data/menu-options';

export const MenuList: FC<{ isNarrowBar: boolean }> = (props) => {
    const { data: session } = useSession();
    const roles = session?.user?.roles;
    const menuItems: Item[] = [];

    if (!!roles?.isCampaignsAdmin) {
        menuItems.push(MENU_OPTIONS[0]);
    }
    if (!!roles?.isMyBotsAdmin) {
        menuItems.push(MENU_OPTIONS[1]);
    }
    if (
        !!roles?.isCallAnalyticsEnabled ||
        !!roles?.isChatAnalyticsEnabled ||
        !!roles?.isSmsAnalyticsEnabled
    ) {
        menuItems.push(MENU_OPTIONS[2]);
    }
    if (!!roles?.isVoicebotCallEnabled) {
        menuItems.push(MENU_OPTIONS[3]);
    }

    return (
        <>
            {menuItems.map((item) => {
                return (
                    <NavItem
                        key={item.id}
                        item={item}
                        level={1}
                        isNarrowBar={props.isNarrowBar}
                    />
                );
            })}
        </>
    );
};

const NavItem: FC<{ item: Item; level: number; isNarrowBar: boolean }> = ({
    item,
    level,
    isNarrowBar,
}) => {
    const router = useRouter();
    const { t } = useTranslation();
    const theme: any = useTheme();

    const Icon = item.icon;

    const handleListItemClick = (route = '') => {
        if (route && route !== '') {
            router.push(route);
        }
    };

    return (
        <ListItemButton
            onClick={() => handleListItemClick(item.url)}
            disabled={item.disabled}
            sx={{
                borderRadius: `${theme.borderRadius}px`,
                mb: 0.5,
                alignItems: 'flex-start',
                backgroundColor:
                    level > 1 ? 'transparent !important' : 'inherit',
                py: level > 1 ? 1 : 1.25,
                pl: `${level * 24}px`,
                justifyContent: isNarrowBar ? 'center' : 'initial',
            }}
            selected={router.pathname.includes(`${item.url}`)}
        >
            <ListItemIcon
                sx={{
                    my: 'auto',
                    minWidth: !item?.icon ? 18 : 36,
                    color: 'white',
                }}
            >
                <Icon />
            </ListItemIcon>
            <ListItemText
                primary={
                    <Typography variant='menuItem' color='inherit'>
                        {t(`${item.title}`)}
                    </Typography>
                }
                sx={{ opacity: isNarrowBar ? 0 : 1 }}
            />
        </ListItemButton>
    );
};

import _ from 'lodash';
import { Edge, Node, XYPosition } from 'reactflow';

export const createNewNodeId = (nodeType: string, nodes: Node[]): string => {
    let index = 1;
    const nodeIdBase = _.snakeCase(nodeType) + '_';

    while (nodes.find((node) => node.id === nodeIdBase + index)) {
        index += 1;
    }

    return nodeIdBase + index;
};

export const getInsertPosition = (nodes: Node[]) => {
    let selectedNode = nodes.find((node) => node.selected);
    const isLastNodeSelected =
        selectedNode && nodes.indexOf(selectedNode) === nodes.length - 1;
    let isLast = false;
    if (isLastNodeSelected || !selectedNode) {
        isLast = true;
        selectedNode = nodes[nodes.length - 1];
    }

    const selectedNodePosition: XYPosition = selectedNode
        ? selectedNode.position
        : { x: 0, y: 0 };

    return { selectedNodePosition, isLast, selectedNode };
};

export const getNodeSuccessors = (nodeId: string, edges: Edge[]): string[] => {
    const successors = new Set<string>();
    const queue = [nodeId];

    while (queue.length > 0) {
        const currentNode = queue.shift()!;
        edges?.forEach((edge) => {
            if (edge.source === currentNode && !successors.has(edge.target)) {
                successors.add(edge.target);
                queue.push(edge.target);
            }
        });
    }

    return Array.from(successors);
};

export const shiftSuccessors = (
    successors: string[],
    shiftAmount: number,
    nodes: Node[],
    updateNodePositions: (positions: Record<string, XYPosition>) => void,
    edges: Edge[],
    specificNodeId: string,
) => {
    const shiftedPositions: Record<string, XYPosition> = {};

    successors.forEach((nodeId) => {
        const node = nodes.find((n) => n.id === nodeId);
        if (!node) return;

        const allSuccessors = getNodeSuccessors(nodeId, edges);

        // Check if the specific node is in the successors, to deal with cycles
        if (!allSuccessors.includes(specificNodeId)) {
            shiftedPositions[node.id] = {
                x: node.position.x,
                y: node.position.y + shiftAmount,
            };
        }
    });

    updateNodePositions(shiftedPositions);
};

export function getContrastColor(backgroundColor: string) {
    const color = backgroundColor.replace('#', '');

    const r = parseInt(color.substring(0, 2), 16);
    const g = parseInt(color.substring(2, 4), 16);
    const b = parseInt(color.substring(4, 6), 16);

    const luminance =
        0.2126 * (r / 255) + 0.7152 * (g / 255) + 0.0722 * (b / 255);

    return luminance > 0.5 ? 'black' : 'white';
}

// Utility function to adjust brightness of a color
export function adjustBrightness(color: string, amount: number) {
    const colorWithoutHash = color.replace('#', '');
    const r = parseInt(colorWithoutHash.substring(0, 2), 16);
    const g = parseInt(colorWithoutHash.substring(2, 4), 16);
    const b = parseInt(colorWithoutHash.substring(4, 6), 16);

    const newR = Math.min(255, Math.max(0, r + amount));
    const newG = Math.min(255, Math.max(0, g + amount));
    const newB = Math.min(255, Math.max(0, b + amount));

    // Convert adjusted RGB back to hex
    return `#${newR.toString(16).padStart(2, '0')}${newG
        .toString(16)
        .padStart(2, '0')}${newB.toString(16).padStart(2, '0')}`;
}

// Main function to determine a slightly darker or lighter color
export function getAdjustedContrastColor(backgroundColor: string) {
    // Calculate luminance to check if the color is light or dark
    const color = backgroundColor.replace('#', '');
    const r = parseInt(color.substring(0, 2), 16);
    const g = parseInt(color.substring(2, 4), 16);
    const b = parseInt(color.substring(4, 6), 16);
    const luminance =
        0.2126 * (r / 255) + 0.7152 * (g / 255) + 0.0722 * (b / 255);

    // Adjust by -30 for lighter colors (make x darker) or +30 for darker colors (make x lighter)
    return luminance > 0.5
        ? adjustBrightness(backgroundColor, -30)
        : adjustBrightness(backgroundColor, 30);
}

import { Delete } from '@mui/icons-material';
import { useSession } from 'next-auth/react';
import { FC } from 'react';

import { RoundButton } from './button/icon-button';

import { useTelmaPortalStore } from '@/store/telma-portal-store';
import { useSaveBotVersionEnabled } from '@/utils/use-save-bot-version-enabled';

export interface RemoveNodeButtonProps {
    nodeId: string;
    backgroundColor?: string;
}

export const RemoveNodeButton: FC<RemoveNodeButtonProps> = ({
    nodeId,
    backgroundColor,
}) => {
    const { data: session } = useSession();
    const isBotEditor = !!session?.user?.roles?.isBotEditor;

    const onNodesRemove = useTelmaPortalStore((state) => state.onNodesRemove);

    const saveEnabled = useSaveBotVersionEnabled();

    return (
        <RoundButton
            hoverColor={backgroundColor}
            style={{ marginBottom: '10px' }}
            disabled={!isBotEditor || !saveEnabled}
            onClick={(e: any) => {
                onNodesRemove([nodeId]);
                e.stopPropagation();
            }}
            onMouseDown={(e: any) => e.stopPropagation()}
        >
            <Delete />
        </RoundButton>
    );
};

import {
    Box,
    ClickAwayListener,
    Divider,
    Grid,
    Paper,
    Popper,
    useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FC, useEffect, useRef, useState } from 'react';

import { LanguageList } from './language-list';

import { LanguageButton } from '@/components/button/icon-button';
import MainCard from '@/components/cards/main-card';
import { Transitions } from '@/components/extended/transitions';

// ==============================|| LANGUAGE ||============================== //

export const LanguageSection: FC<{ isNarrowBar: boolean }> = ({
    isNarrowBar,
}) => {
    const theme: any = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

    const [open, setOpen] = useState(false);
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef: any = useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <Box
                sx={{
                    ml: isNarrowBar ? 1.5 : 2,
                    mr: 3,
                    [theme.breakpoints.down('md')]: {
                        mr: 2,
                    },
                }}
                ref={anchorRef}
            >
                <LanguageButton onClick={handleToggle} open={open} />
            </Box>
            <Popper
                placement={
                    isNarrowBar
                        ? 'top-end'
                        : matchesXs
                        ? 'bottom'
                        : 'bottom-end'
                }
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal={!isNarrowBar}
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: isNarrowBar ? [0, -110] : [0, 16],
                            },
                        },
                    ],
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions
                        position={matchesXs ? 'top' : 'top-right'}
                        in={open}
                        {...TransitionProps}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard
                                    border={false}
                                    elevation={16}
                                    content={false}
                                    boxShadow
                                    shadow={theme.shadows[16]}
                                >
                                    <Grid
                                        container
                                        direction='column'
                                        spacing={2}
                                    >
                                        <Grid item xs={12}>
                                            <LanguageList />
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, InputLabel } from '@mui/material';
import { FC, useCallback } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { shallow } from 'zustand/shallow';

import { BotContentTextField } from '../custom-form-text-field';

import { BaseNodeForm } from './base-node-form';

import { OpenQuestionBotModuleData } from '@/domain/bot';
import { NodeFormProps } from '@/domain/node-form-props';
import { useTelmaPortalStore } from '@/store/telma-portal-store';
import { useSaveBotVersionEnabled } from '@/utils/use-save-bot-version-enabled';

export const botOpenQuestionNodeSchema = yup.object({
    question: yup.string().max(500, 'too_long'),
    acknowledgment_prompt: yup.string().max(500, 'too_long'),
});

export const OpenQuestionNodeForm: FC<NodeFormProps> = ({ nodeIndex }) => {
    const [setNode, node, onValidationChange] = useTelmaPortalStore(
        (state) => [
            state.setNode,
            state.editingBot.nodesReactFlow[nodeIndex],
            state.onValidationChange,
        ],
        shallow,
    );

    const { t } = useTranslation();
    const saveEnabled = useSaveBotVersionEnabled();

    const {
        handleSubmit,
        control,
        formState: { isValid },
    } = useForm<OpenQuestionBotModuleData['params']>({
        resolver: yupResolver(botOpenQuestionNodeSchema),
        //@ts-ignore - data type problem
        defaultValues: node?.data?.params,
    });

    const changeValid = useCallback(() => {
        onValidationChange(!!isValid, nodeIndex);
    }, [isValid, nodeIndex, onValidationChange]);

    changeValid();

    const botNodeSubmit: SubmitHandler<OpenQuestionBotModuleData['params']> = (
        formData,
    ) => {
        setNode(nodeIndex, formData);
    };

    return (
        <BaseNodeForm nodeId={node.id} nodeTitle={node.type}>
            <form onBlur={handleSubmit(botNodeSubmit)}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                    }}
                >
                    <Box>
                        <InputLabel className='input-label-card'>
                            {t('question')}
                        </InputLabel>
                        <Controller
                            name='question'
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <BotContentTextField
                                    {...field}
                                    fieldError={error}
                                    fullWidth
                                    autoResize
                                    autoFocus
                                    disabled={!saveEnabled}
                                />
                            )}
                        />
                    </Box>

                    <Box>
                        <InputLabel className='input-label-card'>
                            {t('confirmation')}
                        </InputLabel>
                        <Controller
                            name='acknowledgment_prompt'
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <BotContentTextField
                                    {...field}
                                    fieldError={error}
                                    fullWidth
                                    autoResize
                                    disabled={!saveEnabled}
                                />
                            )}
                        />
                    </Box>
                </Box>
            </form>
        </BaseNodeForm>
    );
};

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, InputLabel } from '@mui/material';
import { FC } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { shallow } from 'zustand/shallow';

import { FormTextField } from '../custom-form-text-field';

import { BaseNodeForm } from './base-node-form';

import { CustomInternalModuleBotModuleData } from '@/domain/bot';
import { NodeFormProps } from '@/domain/node-form-props';
import { useTelmaPortalStore } from '@/store/telma-portal-store';
import { useSaveBotVersionEnabled } from '@/utils/use-save-bot-version-enabled';

type CustomInternalModuleBotModuleDataParams =
    CustomInternalModuleBotModuleData['params'] &
        CustomInternalModuleBotModuleData['source'];

export const botCustomInternalModuleNodeSchema = yup.object({
    moduleType: yup.string().max(500, 'too_long'),
    project: yup.string().max(500, 'too_long'),
    path: yup.string().max(500, 'too_long'),
});

export const CustomInternalModuleNode: FC<NodeFormProps> = ({ nodeIndex }) => {
    const [setNode, node] = useTelmaPortalStore(
        (state) => [state.setNode, state.editingBot.nodesReactFlow[nodeIndex]],
        shallow,
    );
    const saveEnabled = useSaveBotVersionEnabled();

    const { t } = useTranslation();

    const { handleSubmit, control } =
        useForm<CustomInternalModuleBotModuleDataParams>({
            resolver: yupResolver(botCustomInternalModuleNodeSchema),
            // @ts-ignore - part of the data type problem
            defaultValues: { ...node?.data?.params, ...node?.data?.source },
        });

    const botNodeSubmit: SubmitHandler<
        CustomInternalModuleBotModuleDataParams
    > = (formData) => {
        setNode(
            nodeIndex,
            { moduleType: formData.moduleType },
            { path: formData.path, project: formData.project },
        );
    };

    return (
        <BaseNodeForm nodeId={node.id} nodeTitle={node.type}>
            <form onBlur={handleSubmit(botNodeSubmit)}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                        alignItems: 'baseline',
                    }}
                >
                    <InputLabel className='input-label-card'>
                        {t('module_type')}
                    </InputLabel>
                    <Controller
                        name='moduleType'
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <FormTextField
                                {...field}
                                fieldError={error}
                                sx={{ flexGrow: 1, flexBasis: 0 }}
                                disabled={!saveEnabled}
                            />
                        )}
                    />

                    <InputLabel className='input-label-card'>
                        {t('source_project')}
                    </InputLabel>
                    <Controller
                        name='project'
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <FormTextField
                                {...field}
                                fieldError={error}
                                sx={{ flexGrow: 1, flexBasis: 0 }}
                                disabled={!saveEnabled}
                            />
                        )}
                    />
                </Box>

                <Box>
                    <InputLabel className='input-label-card'>
                        {t('source_path')}
                    </InputLabel>
                    <Controller
                        name='path'
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <FormTextField
                                {...field}
                                fieldError={error}
                                fullWidth
                                disabled={!saveEnabled}
                            />
                        )}
                    />
                </Box>
            </form>
        </BaseNodeForm>
    );
};

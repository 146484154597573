import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { shallow } from 'zustand/shallow';

import { BaseNodeForm } from '../base-node-form';

import { RatingDialogueBlock } from './rating-dialog-block';
import { RatingScaleBlock } from './rating-scale-block';
import { RetryOptionsBlock } from './retry-options-block';

import { NumericRatingBotModuleData } from '@/domain/bot';
import { NodeFormProps } from '@/domain/node-form-props';
import { useTelmaPortalStore } from '@/store/telma-portal-store';

export const botNumericRatingNodeSchema = yup.object().shape({
    max: yup
        .number()
        .required('value_required')
        .typeError('numbers_only')
        .min(0)
        .integer(),
    min: yup
        .number()
        .required('value_required')
        .typeError('numbers_only')
        .min(0)
        .integer(),
    retries: yup
        .number()
        .required('value_required')
        .typeError('numbers_only')
        .min(0)
        .integer(),
    prompt: yup.string().max(1000, 'too_long'),
    out_of_bounds_prompt: yup.string().max(1000, 'too_long'),
    not_found_prompt: yup.string().max(1000, 'too_long'),
    found_prompt: yup.string().max(1000, 'too_long'),
    try_again_prompt: yup.string().max(1000, 'too_long'),
    max_retries_reached_prompt: yup.string().max(1000, 'too_long'),
});

export const NumericRatingNodeForm: FC<NodeFormProps> = ({ nodeIndex }) => {
    const [setNode, node] = useTelmaPortalStore(
        (state) => [state.setNode, state.editingBot.nodesReactFlow[nodeIndex]],
        shallow,
    );

    const { handleSubmit, control } = useForm<
        NumericRatingBotModuleData['params']
    >({
        resolver: yupResolver(botNumericRatingNodeSchema),
        //@ts-ignore - data type problem
        defaultValues: node?.data?.params,
    });

    const botNodeSubmit: SubmitHandler<NumericRatingBotModuleData['params']> = (
        formData,
    ) => {
        setNode(nodeIndex, formData);
    };

    return (
        <BaseNodeForm nodeId={node.id} nodeTitle={node.type}>
            <form onBlur={handleSubmit(botNodeSubmit)}>
                <div className='grid'>
                    <RatingScaleBlock nodeIndex={nodeIndex} control={control} />

                    <RatingDialogueBlock
                        nodeIndex={nodeIndex}
                        control={control}
                    />

                    <RetryOptionsBlock
                        nodeIndex={nodeIndex}
                        control={control}
                    />
                </div>
            </form>
        </BaseNodeForm>
    );
};

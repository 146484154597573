export const isInRange = (
    breakpoint: number,
    min: number | undefined,
    max: number | undefined,
): boolean | void => {
    if (min && max) {
        if (min < max) {
            return breakpoint >= min && breakpoint <= max;
        } else {
            return breakpoint <= min && breakpoint >= max;
        }
    }
};

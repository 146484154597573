import { t } from 'i18next';

export interface messageWithInterpolation {
    key: string;
    values: Record<string, string | number>;
}
export const displayErrorMessage = (
    errorMessage: string | messageWithInterpolation | undefined,
    isError: boolean,
) => {
    if (isError) {
        if (typeof errorMessage === 'string') {
            return t(errorMessage);
        }

        if (
            typeof errorMessage === 'object' &&
            errorMessage.key &&
            errorMessage.values
        ) {
            return t(errorMessage.key, errorMessage.values);
        }

        return t('something_wrong');
    }

    return null;
};

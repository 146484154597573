import { Edge } from 'reactflow';

import { OutputType } from '@/domain/port';

/**
 * Creates edge in graph
 * @param fromNodeId - node given edge starts from
 * @param toNodeId - node given edge goes to
 * @param outputType - which type of output the edge represents
 * @returns object representing edge in flow graph
 */

export const createSimpleEdge = (
    fromNodeId: string,
    toNodeId: string,
    fromPortId: string,
) => {
    const outputType = fromPortId.substring(
        fromPortId.length - 1,
    ) as OutputType;

    const newEdge = {
        id: `${fromNodeId}_${toNodeId}_${outputType}`,
        from: fromNodeId,
        to: toNodeId,
        fromPort: `${fromNodeId}_${outputType}`,
        toPort: `${toNodeId}_I`,
    };

    return newEdge;
};

export const createSimpleEdgeReactFlow = (
    fromNodeId: string,
    toNodeId: string,
    fromPortId: string,
) => {
    const outputType = fromPortId.substring(
        fromPortId.length - 1,
    ) as OutputType;

    const newEdge = {
        id: `${fromNodeId}_${toNodeId}_${outputType}`,
        type: 'CustomDeletableEdge',
        source: fromNodeId,
        target: toNodeId,
        sourceHandle: `${fromNodeId}_${outputType}`,
    } as Edge;

    return newEdge;
};

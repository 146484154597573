import { Immutable } from 'immer';
import { create, StateCreator } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import {
    EditableBotVersionSlice,
    editableBotVersionSlice,
} from './editable-bot-version-slice';
import { LayoutSlice, layoutSlice } from './layout-slice';

export type TelmaPortalStore = LayoutSlice & EditableBotVersionSlice;

export type ImmerStateCreator<T> = StateCreator<
    T,
    [['zustand/immer', never], never],
    [],
    T
>;

export const useTelmaPortalStore = create<TelmaPortalStore>()(
    immer(
        devtools(
            (...a) => ({
                ...layoutSlice(...a),
                ...editableBotVersionSlice(...a),
            }),
            {
                name: 'telmaPortalStore',
            },
        ),
    ),
);

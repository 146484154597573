import dagre from '@dagrejs/dagre';
import { Edge, Node } from 'reactflow';

import { BotModuleData } from '@/domain/bot';

// In order to keep this example simple the node width and height are hardcoded.
// In a real world app you would use the correct width and height values of
// const nodes = useStoreState(state => state.nodes) and then node.__rf.width, node.__rf.height

export const nodeWidth = 250;
export const nodeHeight = 70;

export const getLayoutedElements = (
    nodes: Node<BotModuleData>[],
    edges: Edge[],
) => {
    const dagreGraph = new dagre.graphlib.Graph();

    dagreGraph.setDefaultEdgeLabel(() => ({}));
    dagreGraph.setGraph({ rankdir: 'TB' });

    nodes.forEach((node) => {
        dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    return dagreGraph;
};

import { AppBar, Toolbar, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { signIn, useSession } from 'next-auth/react';
import { CSSProperties, FC, PropsWithChildren, useEffect } from 'react';

import { Header } from '../layout/main-layout/header';

import { Sidebar } from '@/layout/main-layout/sidebar';
import { useTelmaPortalStore } from '@/store/telma-portal-store';
import { exportDynamic } from '@/utils/export-dynamic';

const Layout: FC<PropsWithChildren> = ({ children }) => {
    const opened = useTelmaPortalStore((state) => state.opened);

    const { data: session } = useSession();

    useEffect(() => {
        // @ts-ignore
        if (session?.tokenError === 'RefreshAccessTokenError') {
            signIn('keycloak'); // Force sign in to hopefully resolve error
        }
    }, [session]);

    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const isNarrowBar = !opened && matchUpMd;

    const mainStyle: CSSProperties = {
        boxSizing: 'border-box',
        ...(opened
            ? { minWidth: `calc(100vw - 250px)` }
            : isNarrowBar
            ? { minWidth: 'calc(100% - 64px)' }
            : { minWidth: '100%' }),
        minHeight: isNarrowBar ? '100vh' : 'calc(100vh - 80px)',
        ...(opened
            ? //@ts-ignore
              { marginLeft: `${theme.mixins.drawer.width}px` }
            : isNarrowBar
            ? { marginLeft: `64px` }
            : { marginLeft: '0px' }),
        transition: 'all 225ms cubic-bezier(0,0,0.2,1) 0s',
        transform: 'none',
        padding: isNarrowBar ? '16px' : '32px',
        background: theme.palette?.primary.ultraLight,
        overflowX: 'hidden',
        scrollbarGutter: 'stable',
    };

    return (
        <>
            <AppBar
                position='fixed'
                color='inherit'
                elevation={0}
                sx={{
                    bgcolor: theme.palette.primary.main,
                    transition: opened
                        ? theme.transitions.create('width')
                        : 'none',
                }}
            >
                {!isNarrowBar && (
                    <Toolbar>
                        <Header />
                    </Toolbar>
                )}
            </AppBar>
            <div
                style={{
                    marginTop: isNarrowBar ? undefined : 80,
                    display: 'flex',
                    flexDirection: 'column',
                    overflowX: 'clip',
                }}
            >
                <Sidebar />
                <main style={mainStyle}>{children}</main>
            </div>
        </>
    );
};

export default exportDynamic(Layout);

import { Delete } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { cs } from 'date-fns/locale/cs';
import { enUS } from 'date-fns/locale/en-US';
import { type AppProps } from 'next/app';
import { SessionProvider } from 'next-auth/react';
import { closeSnackbar, SnackbarProvider } from 'notistack';
import { useTranslation } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import 'reactflow/dist/style.css';
import { shallow } from 'zustand/shallow';

import '../../lib/mchat/dist/styles/base.css';
import '../assets/scss/global.scss';
import '../components/bot/nodes/node-styles.css';
import { useTelmaPortalStore } from '../store/telma-portal-store';
import themes from '../themes';

import Layout from '@/components/layout';
import { useKeyboardListener } from '@/utils/keyboard-shortcuts';
import 'i18n';

const queryClient = new QueryClient();

export default function App({
    Component,
    pageProps: { session, ...pageProps },
}: AppProps) {
    useKeyboardListener();
    const customization = useTelmaPortalStore(
        (state) => ({
            opened: state.opened,
            setMenu: state.setMenu,
        }),
        shallow,
    );
    const { i18n } = useTranslation();

    return (
        <SessionProvider session={session}>
            <ThemeProvider theme={themes(customization)}>
                <QueryClientProvider client={queryClient}>
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={i18n.language == 'cs' ? cs : enUS}
                    >
                        <SnackbarProvider
                            autoHideDuration={6000}
                            preventDuplicate={true}
                            maxSnack={4}
                            action={(snackbarId) => (
                                <IconButton
                                    onClick={() => closeSnackbar(snackbarId)}
                                >
                                    <Delete />
                                </IconButton>
                            )}
                        >
                            <Layout>
                                <Component {...pageProps} />
                            </Layout>
                        </SnackbarProvider>
                    </LocalizationProvider>
                </QueryClientProvider>
            </ThemeProvider>
        </SessionProvider>
    );
}

import { Box, Card, InputLabel, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { RemoveNodeButton } from '../remove-node-button';

interface BaseNodeFormProps {
    nodeId: string;
    nodeTitle?: string;
    children: ReactNode;
}
export const BaseNodeForm: FC<BaseNodeFormProps> = ({
    nodeId,
    nodeTitle,
    children,
}) => {
    const { t } = useTranslation();
    const theme: any = useTheme();

    return (
        <Card
            sx={{
                p: 2,
                background: theme.palette.secondary.bgPinkDark,
                boxShadow: '0 2px 1px -1px rgba(0, 0, 0, 0.2)',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Typography variant='cardTitle'>
                    {t(`nodes.${nodeTitle}` ?? '')}
                </Typography>

                <RemoveNodeButton nodeId={nodeId} />
            </Box>

            {children}

            <Box sx={{ mt: 3 }}>
                <InputLabel className='input-label-card'>
                    {t('node_id')}
                </InputLabel>
                <TextField value={nodeId} fullWidth disabled />
            </Box>
        </Card>
    );
};
